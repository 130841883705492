import { authFetch } from 'api';
import { OFFICES_API_BASE_ROUTE } from 'settings/api-settings';
import { getAccessToken } from 'utils/utils';

export const getOffices = async () => {
  const response = await authFetch(OFFICES_API_BASE_ROUTE);



  /*const at = await getAccessToken()

  const response = await fetch(OFFICES_API_BASE_ROUTE, {
    headers: {
      Authorization: `Bearer ${at}`
    }
  });*/



  if(response.ok) {
    const offices = await response.json();
    return offices.map((office) => ({
      ...office,
      createdDate: new Date(office.createdDate),
      modifiedDate: office.modifiedDate && new Date(office.modifiedDate),
    }));
  }
  else if(response.status === 401) {
    throw Error('REDIRECT_TO_LOGIN');
  }
  else if(response.status >= 500) {
    throw Error('Something went wrong loading the offices.');
  }
};

export const getOffice = async (officeId) => {
  const url = OFFICES_API_BASE_ROUTE + '/' + officeId;
  const response = await authFetch(url);
  const office = await response.json();
  return office;
};

export const createOffice = async (officeCreateData) => {
  // await new Promise(res => setTimeout(res, 20000));
  const response = await authFetch(OFFICES_API_BASE_ROUTE, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(officeCreateData),
  });
  if (response.status === 201) {
    
    const office = await response.json();
    
    return {
      ...office,
      createdDate: new Date(office.createdDate),
      modifiedDate: office.modifiedDate && new Date(office.modifiedDate)
    };
  }
  throw `Unhandled status code ${response.status} for createOffice`;
};

export const updateOffice = async (officeId, officeEditData) => {
  const url = OFFICES_API_BASE_ROUTE + '/' + officeId;
  const response = await authFetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(officeEditData),
  });

  const office = await response.json();
    
    return {
      ...office,
      createdDate: new Date(office.createdDate),
      modifiedDate: office.modifiedDate && new Date(office.modifiedDate)
    };
};


export const deleteOffice = async (officeId) => {
  const response = await authFetch(OFFICES_API_BASE_ROUTE + '/' + officeId, {
    method: 'DELETE'
  });

  if(!response.ok) {
    throw response;
  }
};