import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList, ComboBox  } from "@progress/kendo-react-dropdowns";

import { useState } from 'react';
// TODO: move outside
const maxLengthValidator = (value, maxLength) =>
  value?.trim().length > maxLength ? `Max ${maxLength} characters` : '';
  
const requiredValidator = (value) => {return (value?.trim() === '' ? 'Required' : '')};

const emailValidator = (value) => {let emailRegExp = new RegExp('^(.+)@(.+)\\.(.+)$'); return emailRegExp.test(value) ? '' : 'Value is not a valid email'};

const clientValidator = (value, data) => {

  if( (!data.personId) && !value){
    return 'Required'
  }

  if( (data.clientId) && !value){
    return 'Required'
  }

  return ''
  //let r = ((value > 0 || !data.isFocusPerson) ? '' : 'Required')
  //console.log('clientValidator', r, value, data)
  //return r
};

const personNameValidator = (firstName) =>
  requiredValidator(firstName) || maxLengthValidator(firstName, 200);

const personEmailValidator = (email) =>
  requiredValidator(email) || maxLengthValidator(email, 200) || emailValidator(email);

const ErrorInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};


const ErrorComboBox = (fieldRenderProps) => {
  const { validationMessage, visited, comboData,value,onChange, defaultValue, dataItemKey, textField, ...others } = fieldRenderProps;
  const dv = comboData?.find( e => e.clientId == defaultValue)

const [state, setState] = useState({
  ...dv,
  });

  const handleChange = (event) => {
    let v = event.target.value || {}
    onChange({value:v.clientId})
    setState(event.target.value);
  };



  return (
    <div>


       <ComboBox
                    style={{
                      width: "100%",
                    }}
                    data={comboData}
                    dataItemKey={dataItemKey}
                    textField={textField}
                    value={state}
                    onChange={handleChange}
                    
                    {...others}
                  />

     
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};


const PersonForm = (props) => {


  return (
    <Form
      initialValues={props.data}
      onSubmit={props.onSubmitClick}
      render={(formRenderProps) => (
        <FormElement
          style={{
            maxWidth: 650,
          }}
        >
          <fieldset className="k-form-fieldset">
            <legend className="k-form-legend">
              {props.data.personId ? 'Edit' : 'Create'} Person
            </legend>
            <div className="mb-3">
              <Field
                name="firstName"
                component={ErrorInput}
                label="First Name"
                validator={personNameValidator}
                disabled={!props.data.editAvailable}
              />
            </div>
            <div className="mb-3">
              <Field
                name="lastName"
                component={ErrorInput}
                label="Last Name"
                validator={personNameValidator}
                disabled={!props.data.editAvailable}
              />
            </div>
            
            <div className="mb-3">
              <Field
                name="email"
                component={ErrorInput}
                label="Email"
                validator={personEmailValidator}
                disabled={!props.data.editAvailable}
              />
            </div>
            <div className="mb-3">
              <Field
              comboData={props.data.clients}
                name="clientId"
                component={ErrorComboBox}
                label="Client"
                textField="clientName"
                dataItemKey="clientId"
                defaultValue={props.data.clientId}
                validator={(value) => {return clientValidator(value,props.data)}}
                disabled={!props.data.clientChangeAvailable}
              />
     
             

            </div>
            {props.data.personId && <div>
              <Checkbox disabled={true} defaultChecked={props.data.isFocusPerson} label={"Is focus person"} />&nbsp;&nbsp;
              <Checkbox disabled={true} defaultChecked={props.data.isRater} label={"Is rater"} />
            </div>}
          </fieldset>
          <div className="k-form-buttons flex-buttons">
            <Button
              type="button"
              themeColor="inverse"
              onClick={props.onCancelClick}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!formRenderProps.allowSubmit}
              themeColor="primary"
            >
              Submit
            </Button>
              <div className="flex-spacer"></div>
            {props.data.deleteAvailable && <Button
                type="button"
                themeColor="error"
                className="float-right"
                onClick={props.onDeleteItem}
            >
                Delete
            </Button>}

          </div>
        </FormElement>
      )}
    />
  );
};

export default PersonForm;
