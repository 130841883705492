import React, { useState, useEffect } from "react";
import { Form, Field, FormElement, FieldWrapper } from '@progress/kendo-react-form';
import { Error, FloatingLabel } from '@progress/kendo-react-labels';
import { Input} from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { getUserInfo, setUserOffice } from "api";
import { getOffices } from 'api/offices';
import CustomField from 'components/shared/forms/CustomField';
import CustomComboBox from 'components/shared/forms/CustomComboBox';
import LoadingPanel from 'components/shared/LoadingPanel';
import { ROUTES } from 'routes';
import { useHistory } from 'react-router';
import useNotifications from 'hooks/use-notifications';
import { createErrorNotification, createSuccessNotification } from 'utils/notifications-creator';

import {
  validateRequiredObject,
} from 'utils/validations';

const validateOffice = (data) => {
  return (
    validateRequiredObject(data)
  );
};

const OfficeSet = (props) => {
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({officeId:null});
  const [offices, setOffices] = useState([]);
  const [formdata, setFormdata] = useState({officeId:null});
  const [currentOffice, setCurrentOffice] = useState();
  const notifications = useNotifications();
  const history = useHistory();

  useEffect(() => {
    getUserInfo().then(data => {
        setUserInfo(data)
        props.onUserInfoChange(data)
    })

}, [])


useEffect(() => {

  console.warn('fetching offices...', userInfo.officeId);
  if(userInfo.officeId == 0 && !userInfo.isSuperAdmin){
  
 

  try {
    getOffices().then((resp) =>{
    
      setOffices(resp)
    });
    
  } catch (err) {
    console.error(err.message);
  } finally {
    //setLoading(false);
  }
}
    
}, [ userInfo.officeId ]);


const fieldChangeHandler = (e) => {
  setFormdata({
    ...formdata,
    [e.target.name]: e.value,
  });
};

const handleSubmit = async (dataItem) => {

  const p = props
  setLoading(true);
  try {

    console.log('dataItem', JSON.stringify(dataItem),  JSON.stringify(dataItem.office.officeId))
      await setUserOffice(dataItem.office.officeId)

      notifications.push(
        createSuccessNotification('Office set successfully.')
      );

      sessionStorage.setItem('officeId',1)
      window.location.reload()

    } catch (err) {
      notifications.push(
      createErrorNotification('Something was wrong updating the office association.')
      );
    } finally {
      setLoading(false);
    }

};


  return (
    <>
      {(userInfo.officeId == 0 && !userInfo.isSuperAdmin) && <div className="setOfficePage">
      {loading && <LoadingPanel />}
        <Form
      initialValues={formdata}
      onSubmit={handleSubmit}
      render={(formRenderProps) => (
        <FormElement
          style={{
            maxWidth: 650,
          }}
        >
          <fieldset className="k-form-fieldset">
            <legend className="k-form-legend">
              Choose an office
            </legend>
            <div className="mb-3">

            <CustomField
                name="office"
                component={CustomComboBox}
                data={offices}
                dataItemKey="officeId"
                textField="officeName"
                label="Office *"
                onChange={fieldChangeHandler}
                validator={validateOffice}
              />

            </div>
          </fieldset>
          <div className="k-form-buttons">
            <Button
              type="submit"
              disabled={!formRenderProps.allowSubmit}
              themeColor="primary"
            >
              Submit
            </Button>
          </div>
        </FormElement>
      )}
    />


        
        </div>}
      {(userInfo.officeId > 0 || userInfo.isSuperAdmin) && props.children}
    </>
  );
};

export default OfficeSet;
