import React, { useState, useEffect } from "react";
import CustomDrawer from "components/layout/CustomDrawer";
import { useHistory } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { ROUTES } from 'routes';
import { pickLanguage } from 'utils/utils';
import { getCompetencyModel, updateCompetencyResource } from "api/competency-models";

import CRUDPage from 'components/shared/CRUDPage';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

import BehaviorDetail from '../behavior/BehaviorDetail';
import BehaviorCreate from '../behavior/BehaviorCreate';

import HtmlInput from 'components/shared/forms/HtmlInput';

import { Route, Switch } from 'react-router-dom';
import EditCommandCell from 'components/shared/EditCommandCell';
import { reorderBehavior } from "api/behaviorMarkers";
import ReorderComboBox from "components/shared/ReorderComboBox";

import useNotifications from 'hooks/use-notifications';
import {
    createSuccessNotification,
    createErrorNotification,
  } from 'utils/notifications-creator';

  import CustomField from 'components/shared/forms/CustomField';
import CustomInput from 'components/shared/forms/CustomInput';
import {
    validateMaxLength,
    validateRequiredArray,
    validateRequiredObject,
    validateRequiredString,
  } from 'utils/validations';
import { deleteCompetency } from "api/competency";
  

const validateRequired = (value) => {
    return (
      validateRequiredString(value) || validateMaxLength(value, 200)
    );
  };

const initialSort = [
    {
        field: "sort",
        dir: "asc",
    },
];


const CompetencyModelDetail = (props) => {
    const history = useHistory();
    const [detail, setDetail] = React.useState({ behaviors: [], localization: [] });
    const [drawerIsOpen, setDrawerIsOpen] = React.useState(true);
    const [drawerIsLoading, setDrawerIsLoading] = React.useState(true);
    const [refresh, setRefresh] = React.useState(0);
    const notifications = useNotifications();
    const [competencyStatus, setCompetencyStatus] = React.useState('')

    const onCloseDrawer = () => {
        setDrawerIsOpen(false)
        history.push(ROUTES.COMPETENCY_MODEL.EDIT.replace(':competencyModelId', props.match.params.competencyModelId));
    }

    useEffect(() => {
        setDrawerIsOpen(true)
        setDrawerIsLoading(true)

        getCompetencyModel(props.match.params.competencyModelId).then(data => {
            //data.createdDateFormatted = new Date(data.createdDate).toLocaleString()//data.createdDate.substring(0,16)
            //data.modifiedDateFormatted = data.modifiedDate ? new Date(data.modifiedDate).toLocaleString() : '-'
            console.log('props.match.params.competencyId', props.match.params.competencyId)
            const f = data.competencies.find(c => c.id == props.match.params.competencyId)
            setCompetencyStatus(data.status)
            console.log('found', f)
            setDetail(f);
            setDrawerIsLoading(false)

        })
    }, [props.match.params.clientId, refresh])

    const handleSubmit = async (i, e) => {

        const p = props
        setDrawerIsLoading(true)

        const data = {
            ...e,
            competencyId:props.match.params.competencyId,
            competencyModelId:props.match.params.competencyModelId
        }


        try {
            await updateCompetencyResource(data)
    
            notifications.push(
              createSuccessNotification('Item updated successfully.')
            );
            setRefresh(refresh + 1)
            p.onChange()

            //history.replace(LIST_OFFICE_ROUTE);
          } catch (err) {
            notifications.push(
              createErrorNotification('Something was wrong updating the item.')
            );
          } finally {
            setDrawerIsLoading(false)
          }

    };

    const onAddCompetency = (e,isPositive,position) => {
        console.log('onAddCompetency', e, isPositive)

        history.push(ROUTES.BEHAVIOR.ADD.replace(':competencyModelId', props.match.params.competencyModelId).replace(':competencyId', props.match.params.competencyId) + '?ispositive=' + isPositive + '&pos=' + position);

    }

    const onEditBehavior = (e) => {
        console.log('onEditBehavior', e)
        history.push(ROUTES.BEHAVIOR.EDIT.replace(':competencyModelId', props.match.params.competencyModelId).replace(':competencyId', props.match.params.competencyId).replace(':behaviorId', e.dataItem.id));
        //history.push(ROUTES.BEHAVIOR.EDIT.replace(':competencyModelId', props.match.params.competencyModelId).replace(':competencyId', e.dataItem.id));
    }


    const [selected, setSelected] = React.useState(0);

    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    const onOrderChange = (order,id) =>{
        console.log('onOrderChange>', order,id)
        reorderBehavior(id, order).then(data => {
            setRefresh(refresh + 1)
        })
        
    }


    const onDeleteCompetency = async () => {
        
        const confirm = window.confirm(
            'Are you sure you want to delete the competency?'
          );
          if (confirm) {
            const p = props

            try {
            await deleteCompetency(props.match.params.competencyId)
            onCloseDrawer()

            notifications.push(
                createSuccessNotification('Competency delete successfully.')
            );
            setRefresh(refresh + 1)
            p.onChange()
    
            } catch (err) {
            notifications.push(
                createErrorNotification('Something was wrong deleting the competency.')
            );
            } finally {
            setDrawerIsLoading(false)
            }
          }

    };

    const positiveBehaviors = detail.behaviors.filter(b => b.isPositive)
    const negativeBehaviors = detail.behaviors.filter(b => !b.isPositive)

    return (<>
        <CustomDrawer visible={drawerIsOpen} loading={drawerIsLoading} onClose={onCloseDrawer} title={'COMPETENCY DETAIL' } size="">


        {<div> {detail.localization.map((item, i) =>  <TabStrip selected={selected} onSelect={handleSelect} className="fullWidthTab">

<TabStripTab
   
    title={item.cultureCode}
    
>

<Form
                onSubmit={(e) => handleSubmit(i,e)}
                initialValues={item}
                render={(formRenderProps) => (
                    <FormElement>

        <div className="flex-wrap">
            <CustomField name={"name"} label="Name *" component={HtmlInput} maxLength={200} validator={validateRequired} />
            <CustomField name={"description"} label="Description *" component={HtmlInput} maxLength={200} validator={validateRequired} />
 
        </div>
        <div className="flex-wrap">

            <CustomField name={"positiveName"} label="Positive name *" component={HtmlInput} maxLength={200} validator={validateRequired} />
            <CustomField name={"negativeName"} label="Negative name *" component={HtmlInput} maxLength={200} validator={validateRequired} />
            </div>

    <button
                                    type={"submit"}
                                    className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-success k-rounded-md"
                                    disabled={!formRenderProps.allowSubmit}
                                >
                                    Save {item.cultureCode}
                                </button>


                              


    </FormElement>

                )}/>
</TabStripTab>


</TabStrip>)}

</div>}


<div className="innerContent" >
    <div className="tableSection">
                    <div className="tableToolbar">
                        <h3 className="customDrawer__label">Positive Behaviors</h3>
                        
                        <Button
                                    type="button"
                                    onClick={(e) => {onAddCompetency(e,true,positiveBehaviors.length + 1)}}
                                    disabled={competencyStatus != 'D'}
                                >
                                    Add positive behavior
                                </Button>
                    </div>
                        <CRUDPage
                            dataresult={positiveBehaviors}
                            tableFilters={null}
                            initialSort={initialSort}
                            onRowClick={onEditBehavior}
                            showAddButton={false}
                            showRefreshButton={false}
                        >
                            
                            <GridColumn field="sort" title="Order" width={100} />
                            <GridColumn field="id" title="ID" width={100} />
                            
                            <GridColumn
                                title={'ShortTitle'}
                                cell={(props) => (
                                    <td>{pickLanguage(props.dataItem.localization)?.shortTitle}</td>
                                )}
                            />

                            <GridColumn
                                title={'Title'}
                                cell={(props) => (
                                    <td>{pickLanguage(props.dataItem.localization)?.title}</td>
                                )}
                            />

                            <GridColumn cell={(props) => <EditCommandCell text={'Edit'} onClick={()=>{onEditBehavior(props)}}  size="small" props={props} />} width="120" />

                            {competencyStatus == 'D' && <GridColumn
                                title={'Order'}
                                width="130" 
                                cell={(props) => (
                                    <td>{ <ReorderComboBox size={positiveBehaviors?.length} value={props.dataItem?.sort} onChange={(e) => onOrderChange(e,props.dataItem?.id)}/>}</td>
                                )}
                                />}

                        </CRUDPage>
                        </div>
                        <div className="tableSection">
                        <div className="tableToolbar">
                        <h3 className="customDrawer__label">Negative Behaviors</h3>
                        <Button
                                    type="button"
                                    onClick={(e) => {onAddCompetency(e,false,negativeBehaviors.length + 1)}}
                                    disabled={competencyStatus != 'D'}
                                >
                                    Add negative behavior
                                </Button>
                    </div>
                        <CRUDPage
                            dataresult={negativeBehaviors}
                            tableFilters={null}
                            initialSort={initialSort}
                            onRowClick={onEditBehavior}
                            showAddButton={false}
                            showRefreshButton={false}
                        >
                            <GridColumn field="sort" title="Order" width={100} />
                            <GridColumn field="id" title="ID" width={100} />
                            
                            <GridColumn
                                title={'ShortTitle'}
                                cell={(props) => (
                                    <td>{pickLanguage(props.dataItem.localization).shortTitle}</td>
                                )}
                            />

                            <GridColumn
                                title={'Title'}
                                cell={(props) => (
                                    <td>{pickLanguage(props.dataItem.localization).title}</td>
                                )}
                            />

                            <GridColumn cell={(props) => <EditCommandCell text={'Edit'} onClick={()=>{onEditBehavior(props)}}  size="small" props={props} />} width="120" />

                            {competencyStatus == 'D' && <GridColumn
                                title={'Order'}
                                width="130" 
                                cell={(props) => (
                                    <td>{ <ReorderComboBox size={negativeBehaviors?.length} value={props.dataItem?.sort} onChange={(e) => onOrderChange(e,props.dataItem?.id)}/>}</td>
                                )}
                                />}

                        </CRUDPage>
                        <br/>
                        <Button
                                    type="button"
                                    themeColor="error"
                                    className="float-right"
                                    onClick={onDeleteCompetency}
                                    disabled={competencyStatus != 'D'}
                                >
                                    Delete competency
                                </Button>

                        </div>
                        <div className="v-spacer"></div>
                        </div>
              
            {<Switch>
                    <Route path={ROUTES.BEHAVIOR.ADD} render={(props) => <BehaviorCreate {...props} onChange={()=>{setRefresh(refresh + 1)}} />}/>
                    <Route path={ROUTES.BEHAVIOR.EDIT} render={(props) => <BehaviorDetail {...props} onChange={()=>{setRefresh(refresh + 1)}} />}/>
            </Switch>}
        </CustomDrawer>
    </>

    );
};

export default CompetencyModelDetail;