import OfficeGrid from 'components/office/OfficeGrid';
import LoadingPanel from 'components/shared/LoadingPanel';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { DEFAULT_PAGE_SIZE } from 'settings/grid-settings';
import {
  StackLayout,
} from '@progress/kendo-react-layout';
import { getOffices } from 'api/offices';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import { ADD_OFFICE_ROUTE, EDIT_OFFICE_ROUTE, LIST_OFFICE_ROUTE } from 'routes';

import officesReducer from 'reducers/office-grid-reducer';
import {
  createAddOfficeAction,
  createUpdateOfficeAction,
  createReplaceOfficeAction,
} from 'action-creators/office-grid-action-creators';
import useNotifications from 'hooks/use-notifications';
import NewOfficeForm from 'components/office/NewOfficeForm';
import EditOfficeForm from 'components/office/EditOfficeForm';

import { createErrorNotification } from 'utils/notifications-creator';
import PageTitle from 'components/layout/PageTitle';
import RightDrawer from 'components/layout/RightDrawer';
//import useAzureADAccessToken from 'hooks/use-azure-ad-access-token';
import { useRef } from 'react';

// TODO: move into a utils file
const paginate = (items, skip, take) => {
  return items.slice(skip, take + skip);
};

const initialPageState = {
  skip: 0,
  take: DEFAULT_PAGE_SIZE,
};

const OfficePage = (props) => {
  const [loading, setLoading] = useState(true);
  const [offices, dispatchOfficesAction] = useReducer(officesReducer, []);
  const [page, setPage] = useState(initialPageState);
  const [refresh, setRefresh] = useState(0);
  //const accessToken = useAzureADAccessToken();
  // console.log(accessToken);

  const history = useHistory();
  // const notifications = useCallback(useNotifications, []); //();
  // const notifications = useCallback(useNotifications, [])();

  // https://stackoverflow.com/questions/56240067/accessing-context-from-useeffect#answer-56241381
  const notifications = useRef(useNotifications());

  const fetchOffices = useCallback(async (accessToken) => {
    console.log('fetching offices...');
    setLoading(true);
    try {
      const offices = await getOffices();
      dispatchOfficesAction(createReplaceOfficeAction(offices));
    } catch (err) {
      console.error(err.message);
      notifications.current.push(createErrorNotification(err.message));
    } finally {
      setLoading(false);
    }
  }, [ /*notifications*/ ]);

  // const [ counter, setCounter ] = useState(0);

  // notifications.push(createErrorNotification('shit!!'));

  useEffect(() => {
    //if(!!accessToken) {
      fetchOffices();
    //}
    // setCounter(_counter => _counter + 1);
    // console.log('running useEffect...');
  }, [ fetchOffices, refresh ]);

  // useEffect(() => {
  //   if(!!accessToken) {
  //     console.log('calling offices API...');
  //     // fetchOffices(accessToken);
  //     // fetch('/api/office', { headers: { Authorization: 'Bearer ' + accessToken }}).then(resp => console.log(resp));
  //   }
  // }, [ fetchOffices ]);

  const pageChangeHandler = (e) => {
    console.debug('page size changed: ' + JSON.stringify(e.page));
    setPage(e.page);
  };

  const officeAddedHandler = (office) => {
    dispatchOfficesAction(createAddOfficeAction(office));
  };

  const officeUpdatedHandler = (officeId, submittedFormData) => {
    const updateAction = createUpdateOfficeAction(officeId, submittedFormData);
    dispatchOfficesAction(updateAction);
  };

  const addButtonClickHandler = () => {
    history.push(ADD_OFFICE_ROUTE);
  };

  const refreshButtonClickHandler = () => {
    fetchOffices();
  };

  const cancelClickHandler = () => {
    history.replace(LIST_OFFICE_ROUTE);
  };

  const editButtonClickHandler = (officeId) => {
    console.log('edit clicked -> ' + officeId);
    history.push(EDIT_OFFICE_ROUTE.replace(':officeId', officeId));
  };

  const onItemDeletedHandler = (data) => {
      setRefresh(refresh + 1)
  };

  // useEffect(() => {
  //   console.log('useEffect running on OfficePage');
  //   fetchOffices();
  // }, [fetchOffices]);

  const visibleOffices = paginate(offices, page.skip, page.take);

  const location = useLocation();
  const drawerExpanded =
    location.pathname.includes('new') || location.pathname.includes('edit');

  return (
    <section>
      {loading && <LoadingPanel />}
      {/* <div style={{color:'red'}}>{JSON.stringify(accessToken)}</div>
      <h3 style={{color:'red'}}>{counter} times</h3> */}

      <StackLayout orientation="vertical" gap="1em">
        <PageTitle text="Atrain Offices" />

        <OfficeGrid
          offices={visibleOffices}
          totalOffices={offices.length}
          skip={page.skip}
          take={page.take}
          onPageChange={pageChangeHandler}
          onAddButtonClick={addButtonClickHandler}
          onRefreshButtonClick={refreshButtonClickHandler}
          onEditButtonClick={editButtonClickHandler}
          onItemDeleted={onItemDeletedHandler}
        />
      </StackLayout>
      <RightDrawer expanded={drawerExpanded}>
        <Switch>
          <Route path={ADD_OFFICE_ROUTE}>
            <NewOfficeForm
              onOfficeAdded={officeAddedHandler}
              onAddOfficeCancel={cancelClickHandler}
            />
          </Route>
          <Route path={EDIT_OFFICE_ROUTE}>
            <EditOfficeForm
              onOfficeUpdated={officeUpdatedHandler}
              onOfficeUpdateCancel={cancelClickHandler}
            />
          </Route>
        </Switch>
      </RightDrawer>
    </section>
  );
};

export default OfficePage;
