import { REPLACE_CLIENTS, UPDATE_CLIENT, ADD_CLIENT } from 'actions/client-grid-actions';

export const createReplaceClientAction = (clients) => {
  return { type: REPLACE_CLIENTS, clients };
};

export const createUpdateClientAction = (clientId, clientUpdateData) => {
  return { type: UPDATE_CLIENT, clientId, clientUpdateData };
};

export const createAddClientAction = (client) => {
  return { type: ADD_CLIENT, client };
};