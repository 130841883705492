import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import PersonGrid from 'components/person/PersonGrid';
import LoadingPanel from 'components/shared/LoadingPanel';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { DEFAULT_PAGE_SIZE } from 'settings/grid-settings';
import {
    StackLayout,
} from '@progress/kendo-react-layout';
import { getPersons } from 'api/persons-api';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import { ADD_PERSON_ROUTE, EDIT_PERSON_ROUTE, LIST_PERSON_ROUTE } from 'routes';
import { getClients } from "api/clients";

import personsReducer from 'reducers/person-grid-reducer';
import {
    createAddPersonAction,
    createUpdatePersonAction,
    createReplacePersonAction,
} from 'action-creators/person-grid-action-creators';
import useNotifications from 'hooks/use-notifications';
import NewPersonForm from 'components/person/NewPersonForm';
import EditPersonForm from 'components/person/EditPersonForm';

import { createErrorNotification } from 'utils/notifications-creator';
import PageTitle from 'components/layout/PageTitle';
import RightDrawer from 'components/layout/RightDrawer';
import { ComboBox } from "@progress/kendo-react-dropdowns";

// TODO: move into a utils file
const paginate = (items, skip, take) => {
    return items.slice(skip, take + skip);
};

const initialPageState = {
    skip: 0,
    take: DEFAULT_PAGE_SIZE,
};

const PersonPage = (props) => {
    const [loading, setLoading] = useState(true);
    const [persons, dispatchPersonsAction] = useReducer(personsReducer, []);
    const [page, setPage] = useState(initialPageState);
    const [personsData, setPersonsData] = useState([]);
    const [clients, setClients] = useState([]);
    const [personTypes, setPersonTypes] = useState([{id:'0', label:'All'},{id:'1', label:'Focus'},{id:'2', label:'Rater'}]);
    const [refresh, setRefresh] = useState(0);

    const history = useHistory();
    const notifications = useCallback(useNotifications, []); //();

    const fetchPersons = useCallback(async () => {
        console.log('fetching persons...');
        setLoading(true);
        try {
            const persons = await getPersons();
            const clients = await getClients();
            setClients(clients)

            setPersonsData(persons.map(p => {
                let personClient = clients.find(c => {
                    return c.clientId == p.clientId
                })
                let person = { ...p, clientName: personClient?.clientName }
                return person
            }))
            
            dispatchPersonsAction(createReplacePersonAction(personsData));
        } catch (err) {
            console.error(err)
            notifications.push(createErrorNotification(err.message));
        } finally {
            setLoading(false);
        }
    }, [notifications]);


    const pageChangeHandler = (e) => {
        console.debug('page size changed: ' + JSON.stringify(e.page));
        setPage(e.page);
    };

    const personAddedHandler = (person) => {
        dispatchPersonsAction(createAddPersonAction(person));
        setRefresh(refresh + 1)
    };

    const personUpdatedHandler = (personId, submittedFormData) => {
        const updateAction = createUpdatePersonAction(personId, submittedFormData);
        dispatchPersonsAction(updateAction);
        setRefresh(refresh + 1)
    };

    const personDeleteHandler = (personId, submittedFormData) => {
        history.replace(LIST_PERSON_ROUTE);
        setRefresh(refresh + 1)
    };


    const addButtonClickHandler = () => {
        history.push(ADD_PERSON_ROUTE);
    };

    const refreshButtonClickHandler = () => {
        fetchPersons();
    };

    const cancelClickHandler = () => {
        history.replace(LIST_PERSON_ROUTE);
    };

    const editButtonClickHandler = (personId) => {
        history.push(EDIT_PERSON_ROUTE.replace(':personId', personId));
    };

    const onItemDeletedHandler = (data) => {
        setRefresh(refresh + 1)
    };

    useEffect(() => {
        console.log('useEffect running on PersonPage');
        fetchPersons();
    }, [fetchPersons,refresh]);

    const [state, setState] = useState({
        globalFilter: "",
        clientFilter: null,
        personTypeFilter:{id:0}
    })


    const handleChange = (evt) => {
        const value = evt.value;
        setState({
            ...state,
            [evt.target.props.name]: value
        });
        setPage(Object.assign({}, initialPageState, { page: 1 }));
    }

    const handleChangeClientFilter = (event) => {
        setState({
            ...state,
            clientFilter: event.target.value,
        });
        setPage(Object.assign({}, initialPageState, { page: 1 }));
    };

    const handleChangePersontypeFilter = (event) => {
        setState({
            ...state,
            personTypeFilter: event.target.value,
        });
        setPage(Object.assign({}, initialPageState, { page: 1 }));

        console.log(event.target.value)
    };
    

    const customFilterData = (e) => {
        console.log(state.personTypeFilter?.id)
        return e?.filter(o => {
            return (state.clientFilter == null || o.clientId == state.clientFilter?.clientId)
            &&
            ((state.personTypeFilter?.id == 0 || state.personTypeFilter?.id == null) || ( (state.personTypeFilter?.id == 1 && o.isFocusPerson) || (( state.personTypeFilter?.id == 2 && o.isRater))) )
                &&
                (state.globalFilter == '' || state.globalFilter != '' && (o.firstName.toLowerCase().indexOf(state.globalFilter.toLowerCase()) != -1 ||
                    o.lastName.toLowerCase().indexOf(state.globalFilter.toLowerCase()) != -1 ||
                    (o.clientName || '').toLowerCase().indexOf(state.globalFilter.toLowerCase()) != -1))

        })
    }

    const filteredPersons = customFilterData(personsData)
    // console.log('filteredPersons',filteredPersons)
    const visiblePersons = paginate(filteredPersons, page.skip, page.take);
    //console.log('visiblePersons',visiblePersons)
    const location = useLocation();
    const drawerExpanded =
        location.pathname.includes('new') || location.pathname.includes('edit');



    const filterField = <div>

        <Form render={({ allowSubmit, onSubmit }) => (
            <form className="k-form table-filter-form " style={{ display: 'flex', gap: '15px', width: '450px' }} onSubmit={(e) => e.preventDefault()}>

                <Field name="globalFilter" label="Search" component={Input} onChange={handleChange} />

                <ComboBox
                    data={clients}
                    textField="clientName"
                    dataItemKey="clientId"
                    value={state.clientFilter}
                    onChange={handleChangeClientFilter}
                    label="Client"
                    name="Client"

                />

                <ComboBox
                    data={personTypes}
                    textField="label"
                    dataItemKey="id"
                    value={state.personType}
                    onChange={handleChangePersontypeFilter}
                    label="Person type"
                    name="Type"

                />

            </form>
        )}
        />

    </div>





    /*const gridData = (e) => {
        //return customFilterData(orderBy(dataResult, sort)).slice(page.skip, page.take + page.skip)
        return customFilterData(orderBy(dataResult, sort)).slice(page.skip, page.take + page.skip)
    }*/

    return (
        <section>
            {loading && <LoadingPanel />}

            <StackLayout orientation="vertical" gap="1em">
                <PageTitle text="Atrain Persons" />

                <PersonGrid
                    persons={visiblePersons}
                    totalPersons={filteredPersons.length}
                    skip={page.skip}
                    take={page.take}
                    onPageChange={pageChangeHandler}
                    onAddButtonClick={addButtonClickHandler}
                    onRefreshButtonClick={refreshButtonClickHandler}
                    onRefreshRequested={refreshButtonClickHandler}
                    onEditButtonClick={editButtonClickHandler}
                    onItemDeleted={onItemDeletedHandler}
                    filterField={filterField}
                />
            </StackLayout>
            <RightDrawer expanded={drawerExpanded}>
                <Switch>
                    <Route path={ADD_PERSON_ROUTE}>
                        <NewPersonForm
                            onPersonAdded={personAddedHandler}
                            onAddPersonCancel={cancelClickHandler}
                        />
                    </Route>
                    <Route path={EDIT_PERSON_ROUTE}>
                        <EditPersonForm
                            onPersonUpdated={personUpdatedHandler}
                            onPersonUpdateCancel={cancelClickHandler}
                            onPersonDeleted={personDeleteHandler}
                        />
                    </Route>
                </Switch>
            </RightDrawer>
        </section>
    );
};

export default PersonPage;
