import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { validateMaxLength, validateRequiredString } from 'utils/validations';
  
const officeNameValidator = (officeName) =>
  validateRequiredString(officeName) || validateMaxLength(officeName, 200);

const ErrorInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

const OfficeForm = (props) => {
  return (
    <Form
      initialValues={props.data}
      onSubmit={props.onSubmitClick}
      render={(formRenderProps) => (
        <FormElement
          style={{
            maxWidth: 650,
          }}
        >
          <fieldset className="k-form-fieldset">
            <legend className="k-form-legend">
              {props.data.officeId ? 'Edit' : 'Create'} Office
            </legend>
            <div className="mb-3">
              <Field
                name="officeName"
                component={ErrorInput}
                label="Office Name"
                validator={officeNameValidator}
              />
            </div>
          </fieldset>
          <div className="k-form-buttons">
            <Button
              type="button"
              themeColor="inverse"
              onClick={props.onCancelClick}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!formRenderProps.allowSubmit}
              themeColor="primary"
            >
              Submit
            </Button>
          </div>
        </FormElement>
      )}
    />
  );
};

export default OfficeForm;
