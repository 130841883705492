import { Field } from '@progress/kendo-react-form';

const CustomField = (props) => {
  return (
    <div className="mb-3">
      <Field {...props} />
    </div>
  )
};

export default CustomField;