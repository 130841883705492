import { DELETE_PROJECT, CLOSE_PROJECT, PUBLISH_PROJECT, UPDATE_PROJECT, ADD_PROJECT, SET_PROJECTS } from 'actions/projects-grid';

const projectsReducer = (projects, action) => {
  // console.log(projects);
  // console.log(`dispatching action ${action.type}: ${JSON.stringify(action)}`);
  switch (action.type) {
    case SET_PROJECTS:
      // console.error(typeof action.projects);
      return action.projects;
    case ADD_PROJECT:
      return [...projects, action.project];
    case UPDATE_PROJECT:
      return projects.map((p) =>
        p.projectId !== action.projectId ? p : { ...p, ...action.project }
      );
    case PUBLISH_PROJECT:
      return projects.map((p) =>
        p.projectId !== action.projectId
          ? p
          : { ...p, status: 1, startDate: new Date() }
      );
    case CLOSE_PROJECT:
      return projects.map((p) =>
        p.projectId !== action.projectId
          ? p
          : { ...p, status: 2, closedDate: new Date() }
      );
    case DELETE_PROJECT:
      return projects.filter((p) => p.projectId !== action.projectId);
    default:
      throw new Error('Invalid action for reducer: ' + JSON.stringify(action));
  }
};

export default projectsReducer;