import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { PAGE_SIZES } from 'settings/grid-settings';
import ActionGridColumn from './columns/ActionGridColumn';
import LanguageGridColumn from './columns/LanguageGridColumn';
import StatusGridColumn from './columns/StatusGridColumn';
import { Button } from '@progress/kendo-react-buttons';
import {
  Card,
  CardBody,
  CardHeader,
  StackLayout,
} from '@progress/kendo-react-layout';
const ProjectGrid = (props) => {
  return (

    <Card className="clientGrid pageGrid">
      <CardHeader>
        <StackLayout orientation="horizontal">
          <div style={{textAlign:"left"}}>
            <Button
              type="button"
              themeColor="primary"
              onClick={props.onAddButtonClick}
              className="btnAdd"
            >
              Add Project
            </Button>
          </div>
          <div style={{textAlign:"right"}}>
              <div className='clientGridToolbar pageGridToolbar'>
              {props.filterField}


              {<Button
                type="button"
                themeColor="dark"
                onClick={props.onRefreshButtonClick}
              >
                <span className="k-icon k-i-reload"></span> Refresh
  </Button>}
              </div>
         
          </div>
        </StackLayout>
      </CardHeader>
      <CardBody>
        
      <Grid
      data={props.projects}
      total={props.total}
      pageable={{ type: 'input', pageSizes: PAGE_SIZES }}
      skip={props.page.skip}
      take={props.page.take}
      onPageChange={props.onPageChange}
    >
      <GridColumn field="projectId" title="ID" width="100"/>
      <GridColumn
        field="projectName"
        title="NAME"
        className="text-overflow-ellipsis"
      />
      <GridColumn
        field="clientName"
        title="CLIENT NAME"
        className="text-overflow-ellipsis"
      />
      <GridColumn field="status" title="STATUS" cell={StatusGridColumn} />
      <GridColumn
        field="cultureCode"
        title="LANGUAGE"
        cell={LanguageGridColumn}
      />
      <GridColumn field="startDate" title="START DATE" format="{0:d}" />
      <GridColumn field="closedDate" title="CLOSED DATE" format="{0:d}" />

      <GridColumn
            width="120"
            cell={(cellProps) => (
              <td>
                <Button
                  type="button"
                  themeColor="tertiary"
                  size="small"
                  onClick={()=>{props.onEditClick(cellProps.dataItem.projectId)}}
                >
                  Edit
                </Button>
              </td>
            )}
          />


      <GridColumn
        width="100"
        cell={(cellProps) => (
          <ActionGridColumn
            {...cellProps}
            onEditClick={props.onEditClick}
            onDeleteClick={props.onDeleteClick}
            onPublishClick={props.onPublishClick}
            onCloseClick={props.onCloseClick}
          />
        )}
      />
    </Grid>
        
      </CardBody>
    </Card>


    
  );
};

export default ProjectGrid;
