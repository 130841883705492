import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList, ComboBox  } from "@progress/kendo-react-dropdowns";
import { useState } from 'react';
// TODO: move outside
const maxLengthValidator = (value, maxLength) =>
  value?.trim().length > maxLength ? `Max ${maxLength} characters` : '';
  
const requiredValidator = (value) => {return (value?.trim() === '' ? 'Required' : '')};

const emailValidator = (value) => {let emailRegExp = new RegExp('^(.+)@(.+)\\.(.+)$'); return emailRegExp.test(value) ? '' : 'Value is not a valid email'};

const officeValidator = (value) => {
  const ret = ((value) ? '' : 'Required')
  return ret
};

const bkoUserNameValidator = (firstName) =>
  requiredValidator(firstName) || maxLengthValidator(firstName, 200);

const bkoUserEmailValidator = (email) =>
  requiredValidator(email) || maxLengthValidator(email, 200) || emailValidator(email);

const ErrorInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};


const ErrorComboBox = (fieldRenderProps) => {
  const { validationMessage, visited, comboData,value,onChange, defaultValue, dataItemKey, textField, ...others } = fieldRenderProps;
  const dv = comboData?.find( e => e.officeId == defaultValue)

const [state, setState] = useState({
  ...dv,
  });

  const handleChange = (event) => {
    onChange({value:event.target.value?.officeId})
    setState(event.target.value);
  };

  return (
    <div>

       <ComboBox
                    style={{
                      width: "100%",
                    }}
                    data={comboData}
                    dataItemKey={dataItemKey}
                    textField={textField}
                    value={state}
                    onChange={handleChange}

                    label="Office"
                    name="Office"
                    
                    {...others}
                  />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};


const BkoUserForm = (props) => {
 
  return (
    <Form
      initialValues={props.data}
      onSubmit={props.onSubmitClick}
      render={(formRenderProps) => (
        <FormElement
          style={{
            maxWidth: 650,
          }}
        >
          <fieldset className="k-form-fieldset">
            <legend className="k-form-legend">
              {props.data.adminId ? 'Edit' : 'Create'} Backoffice User
            </legend>
            
            <div className="mb-3">
              <Field
                name="email"
                component={ErrorInput}
                label="Email"
                validator={bkoUserEmailValidator}
                disabled={true}
              />
            </div>
            <div className="mb-3">
              <Field
              comboData={props.data.offices}
                name="officeId"
                component={ErrorComboBox}
                label="Office"
                textField="officeName"
                dataItemKey="officeId"
                defaultValue={props.data.officeId}
                validator={officeValidator}
              />
     
             

            </div>
            
          </fieldset>
          <div className="k-form-buttons">
            <Button
              type="button"
              themeColor="inverse"
              onClick={props.onCancelClick}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!formRenderProps.allowSubmit}
              themeColor="primary"
            >
              Submit
            </Button>
          </div>
        </FormElement>
      )}
    />
  );
};

export default BkoUserForm;
