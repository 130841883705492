import React, { useState, useEffect } from "react";
import CustomDrawer from "components/layout/CustomDrawer";
import { useHistory } from "react-router-dom";
import { Form, FormElement } from "@progress/kendo-react-form";
import { ROUTES } from 'routes';
import { updateBehaviorResource, deleteBehavior } from 'api/behaviorMarkers';
import { getCompetencyModel } from "api/competency-models";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import useNotifications from 'hooks/use-notifications';
import {
    createSuccessNotification,
    createErrorNotification,
  } from 'utils/notifications-creator';

import CustomField from 'components/shared/forms/CustomField';
import CustomInput from 'components/shared/forms/CustomInput';
import {
    validateMaxLength,
    validateRequiredString,
  } from 'utils/validations';
import HtmlInput from "components/shared/forms/HtmlInput";
import { Button } from '@progress/kendo-react-buttons';
  

const validateRequired = (value) => {
    return (
      validateRequiredString(value) || validateMaxLength(value, 200)
    );
  };

const BehaviorDetail = (props) => {
    const history = useHistory();
    const [detail, setDetail] = React.useState({ behaviors: [], localization: [] });
    const [drawerIsOpen, setDrawerIsOpen] = React.useState(true);
    const [drawerIsLoading, setDrawerIsLoading] = React.useState(true);
    const notifications = useNotifications();
    const [refresh, setRefresh] = React.useState(0);
    const [competencyStatus, setCompetencyStatus] = React.useState('')

    const onCloseDrawer = () => {
        setDrawerIsOpen(false)
        history.push(ROUTES.COMPETENCY.EDIT.replace(':competencyModelId', props.match.params.competencyModelId).replace(':competencyId', props.match.params.competencyId));

    }

    useEffect(() => {
        setDrawerIsOpen(true)
        setDrawerIsLoading(true)

        getCompetencyModel(props.match.params.competencyModelId).then(data => {
            const f = data.competencies.find(c => c.id == props.match.params.competencyId)
            const b = f.behaviors.find(c => c.id == props.match.params.behaviorId)
            setCompetencyStatus(data.status)
            setDetail(b);
            setDrawerIsLoading(false)

        })
    }, [props.match.params.clientId,refresh])

    const onDeleteBehavior = async () => {
        
        const confirm = window.confirm(
            'Are you sure you want to delete the behavior?'
          );
          if (confirm) {
            const p = props

            try {
            await deleteBehavior(props.match.params.behaviorId)
            onCloseDrawer()

            notifications.push(
                createSuccessNotification('Behavior delete successfully.')
            );
            setRefresh(refresh + 1)
            p.onChange()
    
            } catch (err) {
            notifications.push(
                createErrorNotification('Something was wrong deleting the behavior.')
            );
            } finally {
            setDrawerIsLoading(false)
            }
          }

    };
    

    const handleSubmit = async (i, e) => {

        const p = props
        setDrawerIsLoading(true)

        const data = {
            ...e,
            behaviorId:props.match.params.behaviorId
        }


        try {
            await updateBehaviorResource(data)
    
            notifications.push(
              createSuccessNotification('Item updated successfully.')
            );
            setRefresh(refresh + 1)
            p.onChange()

          } catch (err) {
            notifications.push(
              createErrorNotification('Something was wrong updating the item.')
            );
          } finally {
            setDrawerIsLoading(false)
          }

    };

    const [selected, setSelected] = React.useState(0);

    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    return (<>
        <CustomDrawer visible={drawerIsOpen} loading={drawerIsLoading} onClose={onCloseDrawer} title={'BEHAVIOR DETAIL' } size="">


        {!drawerIsLoading && <div> {detail.localization.map((item, i) =>  <TabStrip selected={selected} onSelect={handleSelect} className="fullWidthTab">

<TabStripTab
   
    title={item.cultureCode}
    
>

<Form
                onSubmit={(e) => handleSubmit(i,e)}
                initialValues={item}
                render={(formRenderProps) => (
                    <FormElement>

    <div>
        <div className="mb-3">
            <CustomField name={"shortTitle"} label="Short title *" component={CustomInput} validator={validateRequired} />
        </div>

        <div className="mb-3">
            <CustomField name={"title"} label="Title *" component={HtmlInput} maxLength={200} validator={validateRequired} />
        </div>

    </div>

    <button
                                    type={"submit"}
                                    className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-success k-rounded-md"
                                    disabled={!formRenderProps.allowSubmit}
                                >
                                    Save {item.cultureCode}
                                </button>

                                <Button
                                    type="button"
                                    themeColor="error"
                                    className="float-right"
                                    onClick={onDeleteBehavior}
                                    disabled={competencyStatus != 'D'}
                                >
                                    Delete
                                </Button>

    </FormElement>

                )}/>
</TabStripTab>


</TabStrip>)}

</div>}



            {/*<Switch>
                    <Route path={ROUTES.COMPETENCY.EDIT} component={CompetencyDetail} />
            </Switch>*/}
        </CustomDrawer>
    </>

    );
};

export default BehaviorDetail;