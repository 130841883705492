import classes from 'components/shared/CountryFlag.module.css';

const CountryFlag = (props) => {
  const flagPath = `/assets/flags/${props.cultureCode}.svg`;

  return (
    <img
      src={flagPath}
      className={classes["flag"]}
    />
  );
};

export default CountryFlag;
