import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useState } from 'react';

import { CLIENT_ID } from '../config';

const useAzureADAccessToken = () => {
  const { instance } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  // console.log('------------------------');
  // console.log(accounts);
  // console.log('------------------------');
  // console.log(instance);
  // console.log(instance.getAllAccounts());
  // console.log(instance.getAccount());

  // console.log(account);

  useEffect(() => {
    const account = instance.getAllAccounts()[0];
    // console.log('------------------------');
    if(!!account) {
      // console.log('!!account');
        instance.acquireTokenSilent({ scopes: [`api://${CLIENT_ID}/access_as_user` ], account: account })
        .then(resp => {
          // console.log(resp);
          setAccessToken(resp.accessToken)
        })
        .catch(err => {
          // console.log(err);
          if(err.name === 'InteractionRequiredAuthError') {
            instance.acquireTokenPopup({ scopes: [ `api://${CLIENT_ID}/access_as_user` ] })
              .then(resp => {
                // console.log(resp);
                setAccessToken(resp.accessToken)
              })
              .catch(err => console.error(err));
          }
          else {
            console.error(err);
          }
        });
    }
    else {
      console.log('No account here');
    }
    // console.log('------------------------');
  }, [ instance ]);

  return accessToken;
  // return [ accessToken ];
}

export default useAzureADAccessToken;