import { authFetch } from "api";

const API_BASE_PATH = '/api';

export const getInviteesByProjectId = async (projectId) => {
  const httpResponse = await authFetch(`${API_BASE_PATH}/projects/${projectId}/invitees`);
  if(httpResponse.ok) {

    const data = await httpResponse.json();
    return data.map((person) => ({
      ...person,
      lastEmailSentDate: new Date(person.lastEmailSentDate)
    }));


  }
  throw httpResponse;
}


export const resetInvitation = async (projectId, personId) => {
  const httpResponse = await authFetch(`${API_BASE_PATH}/projects/${projectId}/invitees/${personId}/invitationlink`, {
    method: 'POST'
  });

  if(httpResponse.ok) {
    const data = await httpResponse.json();
    return data;
  }
  
  if(!httpResponse.ok) {
    throw httpResponse;
  }

  return {}
}

