import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import {
  Card,
  CardBody,
  CardHeader,
  StackLayout,
} from '@progress/kendo-react-layout';
import { deleteOffice } from 'api/offices';
import DeleteActionGridColumn from 'components/shared/DeleteActionGridColumn';
import { PAGE_SIZES } from 'settings/grid-settings';

const OfficeGrid = (props) => {
  const editClickHandler = (officeId) => {
    props.onEditButtonClick?.(officeId);
  };

  return (
    <Card>
      <CardHeader>
        <StackLayout orientation="horizontal">
          <div style={{textAlign:"left"}}>
            <Button
              type="button"
              themeColor="primary"
              onClick={props.onAddButtonClick}
            >
              Add Office
            </Button>
          </div>
          <div style={{textAlign:"right"}}>
            <Button
              type="button"
              themeColor="dark"
              onClick={props.onRefreshButtonClick}
            >
              <span className="k-icon k-i-reload"></span> Refresh
            </Button>
          </div>
        </StackLayout>
      </CardHeader>
      <CardBody>
        <Grid
          id={props.id}
          data={props.offices}
          total={props.totalOffices}
          pageable={{ type: 'input', pageSizes: PAGE_SIZES }}
          skip={props.skip}
          take={props.take}
          onPageChange={props.onPageChange}
        >
          <GridColumn field="officeId" title="Office Id" />
          <GridColumn field="officeName" title="Office Name" />
          <GridColumn field="createdDate" title="Created Date" format="{0:d}" />
          <GridColumn
            field="modifiedDate"
            title="Modified Date"
            format="{0:d}"
          />
          <GridColumn
            cell={(props) => (
              <td>
                <Button
                  type="button"
                  themeColor="tertiary"
                  size="small"
                  onClick={editClickHandler.bind(null, props.dataItem.officeId)}
                >
                  Edit
                </Button>
              </td>
            )}
          />

      <GridColumn
        width="100"
        cell={(cellProps) => (
          <DeleteActionGridColumn
            {...cellProps}
            idKey="officeId"
            deleteFunction={deleteOffice}
            onDeleted={props.onItemDeleted}
            isDeleteAvailable={cellProps.dataItem.deleteAvailable}
          />)}
          />
        </Grid>
      </CardBody>
    </Card>
  );
};

export default OfficeGrid;
