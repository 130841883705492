import {
  NotificationGroup,
  Notification,
} from '@progress/kendo-react-notification';
import { Slide } from '@progress/kendo-react-animation';
import NotificationStackContext from 'stores/notification-stack-context';
import { useEffect, useState } from 'react';

const NotificationStack = (props) => {
  return (
    <NotificationGroup
      style={{
        right: '12px',
        bottom: '12px',
        alignItems: 'flex-start',
        flexWrap: 'wrap-reverse',
        zIndex: 99999999,
      }}
    >
      <Slide direction="left" enter exit>
        {props.notifications.map((notification, index) => (
          <Notification
            key={index}
            type={{ style: notification.type, icon: true }}
          >
            {notification.text}
          </Notification>
        ))}
      </Slide>
    </NotificationGroup>
  );
};

const NotificationStackProvider = (props) => {
  const [notifications, setNotifications] = useState([]);

  const pushHandler = (notification) => {
    console.log('handling push...');
    setNotifications((prevNotifications) => [
      notification,
      ...prevNotifications
    ]);
  };

  useEffect(() => {
    if(notifications.length === 0) {
      return;
    }
    //console.log('useEffect notifications');
    const timeout = window.setTimeout(() => {
      setNotifications(prevNotifications => prevNotifications.slice(0, prevNotifications.length - 1));
    }, 3000);
    return () => clearTimeout(timeout);
  }, [ notifications.length ]);

  const context = {
    notifications: notifications,
    push: pushHandler,
  };

  return (
    <NotificationStackContext.Provider value={context}>
      {props.children}
      <NotificationStack notifications={context.notifications} />
    </NotificationStackContext.Provider>
  );
};

export default NotificationStackProvider;
