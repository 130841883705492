import { getAccessToken } from "utils/utils";

const USER_INFO_BASE_ROUTE = '/api/profile'

export const authReq = async(options) => {
  let at = await getAccessToken()
  const update = { ...options };

  update.headers = {
    ...update.headers,
    Authorization: `Bearer ${at}`
  };
  
  return update;
}

export const authFetch = async (url, options) => {
  const response = await fetch(url, await authReq(options));

  return response
}

export const getUserInfo = async () => {
    //let at = await getAccessToken()

    const response = await authFetch(USER_INFO_BASE_ROUTE);

    if(response.ok) {
      const resp = await response.json();
      console.log('getUserInfo resp',resp)
      
      return {
        officeId:resp.officeId || 0,
        ...resp
      }
    }
    else if(response.status === 404) {
        return {
            officeId:0, //hasOffice
            isSuperAdmin:false //role
          }
      }
    else if(response.status === 401) {
      throw Error('REDIRECT_TO_LOGIN');
    }
    else if(response.status >= 500) {
      throw Error('Something went wrong.');
    }
  };

 export const setUserOffice = async (officeId) =>{
    return  authFetch(USER_INFO_BASE_ROUTE, {
        method:'POST', body:JSON.stringify({officeId}),
        headers: {
            'Content-Type': 'application/json'
          },
    })
    .then(response => response.json())
}

/*
function XgetClient(clientId){
    return  fetch(baseApiUrl + "Client/" + clientId).then(response => response.json())
}



function XsaveClient(clientId, data){
    return  fetch(baseApiUrl + "Client/" + clientId, {
        method:'PUT', body:JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
          },
    })
}
*/
export {
    /*getClient,
    saveClient,
    addClient*/
}