export const API_BASE_ROUTE = '/api';

export const OFFICES_API_BASE_ROUTE = API_BASE_ROUTE + '/office';
export const PERSONS_API_BASE_ROUTE = API_BASE_ROUTE + '/person';
export const PERSONS_ANONYMIZE_API_BASE_ROUTE = API_BASE_ROUTE + '/personS';

export const CLIENTS_API_BASE_ROUTE = API_BASE_ROUTE + '/client';
export const RESOURCES_API_BASE_ROUTE = API_BASE_ROUTE + '/resources/ClientApp';
export const BKOUSERS_API_BASE_ROUTE = API_BASE_ROUTE + '/admins';

export const BEHAVIORMARKERS_API_BASE_ROUTE = API_BASE_ROUTE + '/Behavior';
export const COMPETENCY_API_BASE_ROUTE = API_BASE_ROUTE + '/Competency';
