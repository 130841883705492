import { Button } from '@progress/kendo-react-buttons';
import { Form, FormElement } from '@progress/kendo-react-form';
import { getPersonOptionsByClientId } from 'api/dropdown-values';
import CustomComboBox from 'components/shared/forms/CustomComboBox';
import CustomField from 'components/shared/forms/CustomField';
import CustomInput from 'components/shared/forms/CustomInput';
import CustomMultiSelect from 'components/shared/forms/CustomMultiSelect';
import CustomTextArea from 'components/shared/forms/CustomTextArea';
import LoadingPanel from 'components/shared/LoadingPanel';
import useNotifications from 'hooks/use-notifications';
import { useState } from 'react';
import { createErrorNotification } from 'utils/notifications-creator';
import {
  validateMaxLength,
  validateRequiredArray,
  validateRequiredObject,
  validateRequiredString,
} from 'utils/validations';
import HtmlInput from 'components/shared/forms/HtmlInput';

const validateProjectName = (projectName) => {
  return (
    validateRequiredString(projectName) || validateMaxLength(projectName, 200)
  );
};
const validateClient = (client) => validateRequiredObject(client);
const validateCompetencyModel = (client) => validateRequiredObject(client);
const validateLanguage = (language) => validateRequiredObject(language);
const validatePersons = (persons) => validateRequiredArray(persons);

const ProjectForm = (props) => {
  const [formValues, setFormValues] = useState(props.initialValues || {});
  const [loading, setLoading] = useState(false);
  const [personOptions, setPersonOptions] = useState(props.persons || []);

  const notifications = useNotifications();

  const isDeleteAvailable = props.initialValues.projectId && (props.initialValues.status === 0);
  const isPublishAvailable = props.initialValues.projectId && (props.initialValues.status === 0);
  const isCloseAvailable = (props.initialValues.status === 1);
  const isViewAssessmentsAvailable = (props.initialValues.status === 1);


  const fetchPersonOptionsByClient = async (client) => {
    setLoading(true);

    try {
      const personOptions = await getPersonOptionsByClientId(client.id);
      setPersonOptions(personOptions);
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        client,
        persons: [],
      }));
    } catch (httpErrorResponse) {
      notifications.push(
        createErrorNotification(
          "We're sorry! Something was wrong loading the persons belonging to the chosen client. Try again later."
        )
      );
    }

    setLoading(false);
  };

  const submitHandler = (submittedProject) => {
    const project = {
      projectName: submittedProject.projectName,
      projectDescription: submittedProject.projectDescription || null,
      clientId: submittedProject.client?.id,
      competencyModelId: submittedProject.competencyModel?.id,
      cultureCode: submittedProject.language?.cultureCode,
      //officeInChargeId: submittedProject.officeInCharge?.id,
      persons: submittedProject.persons?.map((person) => person.id) ?? [],
      customMessage: submittedProject.customMessage,
    };
    props.onSubmit?.(project);
  };

  const fieldChangeHandler = (e) => {
    // console.log(e.target.name + ' changed to ' + JSON.stringify(e.value));
    setFormValues({
      ...formValues,
      [e.target.name]: e.value,
    });
  };

  const clientChangeHandler = (e) => {
    const client = e.value;
    if (client !== null) {
      fetchPersonOptionsByClient(client);
    } else {
      setPersonOptions([]);
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        client,
        persons: [],
      }));
    }
  };

  const formKey = JSON.stringify({ clientId: formValues.client?.id });

  //console.log(formValues);

  // https://www.telerik.com/kendo-react-ui/components/form/advanced-scenarios/#toc-resetting-the-form-initial-state
  return (
    <>

      {loading && <LoadingPanel />}
      <Form
        initialValues={formValues}
        key={formKey}
        onSubmit={submitHandler}
        render={(formRenderProps) => (
          <FormElement>
            <fieldset className="k-form-fieldset">
              <legend className="k-form-legend">
                {props.title || 'Project Form'}
              </legend>
              <CustomField
                name="projectName"
                component={CustomInput}
                label="Name *"
                maxLength={200}
                onChange={fieldChangeHandler}
                validator={validateProjectName}
              />
              <CustomField
                name="projectDescription"
                component={CustomTextArea}
                label="Description"
                onChange={fieldChangeHandler}
              />
              <CustomField
                name="client"
                component={CustomComboBox}
                onChange={clientChangeHandler}
                data={props.clients}
                dataItemKey="id"
                textField="name"
                label="Client *"
                validator={validateClient}
                disabled={formValues.status != 0}
              />
              {!!formRenderProps.valueGetter('client') ? (
                <CustomField
                  name="persons"
                  component={CustomMultiSelect}
                  data={personOptions}
                  dataItemKey="id"
                  textField="fullName"
                  label="Persons *"
                  onChange={fieldChangeHandler}
                  validator={validatePersons}
                  disabled={formValues.status != 0}
                />
              ) : null}

              <CustomField
                name="competencyModel"
                component={CustomComboBox}
                data={props.competencyModels}
                dataItemKey="id"
                textField="name"
                label="Competency Model *"
                onChange={fieldChangeHandler}
                validator={validateCompetencyModel}
                disabled={formValues.status != 0}
              />
              {/*<CustomField
                name="officeInCharge"
                component={CustomComboBox}
                data={props.offices}
                dataItemKey="id"
                textField="name"
                label="Office in charge *"
                onChange={fieldChangeHandler}
                disabled={formValues.status != 0}
              />*/}
              <CustomField
                name="language"
                component={CustomComboBox}
                data={props.languages}
                dataItemKey="cultureCode"
                textField="name"
                label="Language *"
                onChange={fieldChangeHandler}
                validator={validateLanguage}
                disabled={formValues.status != 0}
              />


            <CustomField onChange={fieldChangeHandler}  height="120px" name="customMessage" label="Mail custom message" component={HtmlInput} disabled={formValues.status != 0}/>

            </fieldset>
            <div style={{display:'flex'}}>
           

              {isPublishAvailable && <Button
                type="button"
                themeColor="success"
                onClick={(e) => {props.onPublishProject(formValues)}}
              >
                Publish project
              </Button>}
              <div style={{flexGrow:1}}></div>
              
              {isDeleteAvailable && <Button
                type="button"
                themeColor="error"
                onClick={(e) => {props.onDeleteProject(formValues)}}
              >
                Delete project
              </Button>}

            </div>
            <div className="k-form-buttons" style={{display:'flex', justifyContent: 'end'}}>
            {isViewAssessmentsAvailable && <Button
                type="button"
                fillMode="flat"
                onClick={(e) => {props.onViewAssessments(formValues)}}
              >
                View assessments
              </Button>}

              <Button
                  type="button"
                  fillMode="flat"
                  onClick={(e) => {props.onViewInvitees(formValues)}}
                >
                  View Invitees
              </Button>
            </div>

            <div className="k-form-buttons" style={{display:'flex'}}>
              <Button
                type="button"
                themeColor="inverse"
                onClick={props.onCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={!formRenderProps.allowSubmit}
                themeColor="primary"
              >
                Submit
              </Button>
              <div style={{flexGrow:1}}></div>
              {isCloseAvailable && <Button
                type="button"
                themeColor="warning"
                onClick={(e) => {props.onCloseProject(formValues)}}
              >
                Close project
              </Button>}

             
            </div>
          </FormElement>
        )}
      ></Form>
    </>
  );
};

export default ProjectForm;
