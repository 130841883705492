import { authFetch } from "api";
import { RESOURCES_API_BASE_ROUTE } from 'settings/api-settings';

export const getResources = async (groupName) => {
  const url = RESOURCES_API_BASE_ROUTE + '/' + groupName;
  const response = await authFetch(url);
  if(response.ok) {
    const resources = await response.json();
    return resources.map((resource) => ({
      ...resource,
      createdDate: new Date(resource.createdDate),
      modifiedDate: resource.modifiedDate && new Date(resource.modifiedDate),
    }));
  }
  else if(response.status === 401) {
    throw Error('REDIRECT_TO_LOGIN');
  }
  else if(response.status === 404) {
    return []
  }
  else if(response.status >= 500) {
    throw Error('Something went wrong loading the resources.');
  }
};

export const getResource = async (resourceId) => {
  const url = RESOURCES_API_BASE_ROUTE + '/' + resourceId;
  const response = await authFetch(url);
  const resource = await response.json();
  return resource;
};

export const createResource = async (resourceCreateData) => {
  // await new Promise(res => setTimeout(res, 20000));
  const response = await authFetch(RESOURCES_API_BASE_ROUTE, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(resourceCreateData),
  });
  if (response.status === 201) {
    return await response.json();
  }
  throw `Unhandled status code ${response.status} for createResource`;
};

export const updateResource = async (resourceId, resourceEditData) => {
  //const url = RESOURCES_API_BASE_ROUTE + '/' + resourceId;
  const url = RESOURCES_API_BASE_ROUTE + '?id=' + resourceId;
  const response = await authFetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(resourceEditData),
  });
};



export const getMultipleResource = async (resourceIds) => {
  let p = []
  resourceIds.forEach(rId => {
    p.push(getResource(rId))
  });

  const resources = await Promise.all(p);

  /*let resps = []
  resources.forEach(r => {
    const r2 = await r.json()
    resps.push(r2)

  });*/
  return resources

};

export const getResourceGroups = async () => {
  const url = RESOURCES_API_BASE_ROUTE;

  const httpResponse = await authFetch(url);
  if(httpResponse.ok) {
    return await httpResponse.json();
  }
  throw httpResponse;
};