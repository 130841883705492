import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from "./msalConfig";

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders. 
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */

//  let accountId = "";

 export const msalInstance = new PublicClientApplication(msalConfig);

//  function handleResponse(response) {
//   console.log(response);
//   if (response !== null) {
//     accountId = response.account.homeAccountId;
//     console.log(accountId);
//     // Display signed-in user content, call API, etc.
//   } else {
//     // In case multiple accounts exist, you can select
//     const currentAccounts = msalInstance.getAllAccounts();
//     console.log(currentAccounts);

//     if (currentAccounts.length === 0) {
//       // no accounts signed-in, attempt to sign a user in
//       msalInstance.loginRedirect(loginRequest);
//     } else if (currentAccounts.length > 1) {
//       // Add choose account code here
//     } else if (currentAccounts.length === 1) {
//       accountId = currentAccounts[0].homeAccountId;
//       console.log(accountId);
//     }
//   }
// }

// msalInstance.handleRedirectPromise().then(handleResponse);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App msalInstance={msalInstance} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
