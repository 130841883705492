import {
  DELETE_PROJECT,
  CLOSE_PROJECT,
  PUBLISH_PROJECT,
  UPDATE_PROJECT,
  ADD_PROJECT,
  SET_PROJECTS,
} from 'actions/projects-grid';

export const createSetProjects = (projects) => {
  return { type: SET_PROJECTS, projects };
};

export const createAddProject = (project) => {
  return { type: ADD_PROJECT, project };
};

export const createUpdateProject = (projectId, project) => {
  return { type: UPDATE_PROJECT, projectId, project };
};

export const createPublishProject = (projectId) => {
  return { type: PUBLISH_PROJECT, projectId };
};

export const createCloseProject = (projectId) => {
  return { type: CLOSE_PROJECT, projectId };
};

export const createDeleteProject = (projectId) => {
  return { type: DELETE_PROJECT, projectId };
};
