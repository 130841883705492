import { withRouter } from 'react-router-dom';
import { Avatar, Menu, MenuItem } from '@progress/kendo-react-layout';
import { ROUTES } from 'routes';
import { useMsal } from '@azure/msal-react';

const LogoutLink = (props) => {
  const { name } = props.item.data.account;
  const nameInitials = name
    .split(' ')
    .map((namePart) => namePart[0].toUpperCase())
    .join('');
  return (
    <>
      Logout
      <Avatar type="text" size="medium">
        <span>{nameInitials}</span>
      </Avatar>
    </>
  );
};

// https://www.telerik.com/kendo-react-ui/components/layout/menu/routing/
const TopMenu = (props) => {
  const { instance, accounts } = useMsal();

  console.log(accounts[0]);

  const selectHandler = (event) => {
    if (event.item.data.isLogout) {
      console.log(instance);
      instance.logoutRedirect({ postLogoutRedirectUri: '/' });
      return;
    }
    props.history.push(event.item.data.route);
  };

  return (
    <div>
      <Menu onSelect={selectHandler}>
        <MenuItem text="Dashboard" data={{ route: ROUTES.DASHBOARD }} />
        {/*<MenuItem text="Competency Models" data={{ route: ROUTES.COMPETENCY_MODEL.LIST }} />*/}
        <MenuItem text="Clients" data={{ route: ROUTES.CLIENT.BASE }} />
        {props.isSuperAdmin && <MenuItem text="Offices" data={{ route: ROUTES.OFFICE.BASE }} />}
        <MenuItem text="Persons" data={{ route: ROUTES.PERSON.BASE }} />
        <MenuItem text="Projects" data={{ route: ROUTES.PROJECTS.LIST }} />
        {props.isSuperAdmin && <MenuItem text="Resources" data={{ route: ROUTES.RESOURCE.BASE }} />}
        {props.isSuperAdmin && <MenuItem text="Backoffice Users" data={{ route: ROUTES.BKOUSER.BASE }} />}
        {props.isSuperAdmin && <MenuItem
          text="Competency models"
          data={{ route: ROUTES.COMPETENCY_MODEL.BASE }}
        />}
        {/*<MenuItem text="Mailing" data={{ route: ROUTES.MAILING.LIST }} />*/}
        {/*<MenuItem text="Clients" data={{ route: ROUTES.CLIENT.LIST }}>*/}
        {/*    <MenuItem text="Team" data={{ route: "/about/team" }} />*/}
        {/*</MenuItem>*/}
        {accounts[0] && (
          <MenuItem
            text="Logout"
            data={{ isLogout: true, account: accounts[0] }}
            render={LogoutLink}
          />
        )}
      </Menu>
    </div>
  );
};

export default withRouter(TopMenu);
