import { getClients } from './clients';
import { getCompetencyModels } from './competency-models';
import { getLanguages } from './languages';
import { getOffices } from './offices';
import { getPersonsByClientId } from './persons';

export const getCompetencyModelOptions = async () => {
  const competencyModels = await getCompetencyModels();
  return competencyModels
    .filter((competencyModel) => competencyModel.status === 'A')
    .map((competencyModel) => ({
      id: competencyModel.id,
      name: competencyModel.name,
    }));
};

export const getLanguageOptions = async () => {
  return await getLanguages();
};

export const getOfficeOptions = async () => {
  const offices = await getOffices();
  return offices.map((office) => ({
    id: office.officeId,
    name: office.officeName,
  }));
};

export const getClientOptions = async () => {
  const clients = await getClients();
  return clients.map((client) => ({
    id: client.clientId,
    name: client.clientName,
  }));
};

export const getPersonOptionsByClientId = async (clientId) => {
  const persons = await getPersonsByClientId(clientId);
  return persons.map((person) => ({
    id: person.personId,
    fullName: person.firstName + ' ' + person.lastName,
  }));
};