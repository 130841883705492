import HtmlEditor from 'components/shared/HtmlEditor';
import CustomFieldWrapper from './CustomFieldWrapper';

const HtmlInput = (props) => {
  const { validationMessage, visited, maxLength, ...others } = props;
  return (
    <CustomFieldWrapper
      validationMessage={validationMessage}
      visited={visited}
      name={props.name}
    >
      <div className="k-floating-label-container">
        <label className='k-label'>{props.label}</label>
        <HtmlEditor {...others} key={others.id}/>
      </div>
      
    </CustomFieldWrapper>
  );
};

export default HtmlInput;