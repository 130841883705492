import React, { useEffect} from "react";
import LoadingPanel from 'components/shared/LoadingPanel';

const CustomDrawer = (props) => {
    const [drawerIsOpening, setDrawerIsOpening] = React.useState('enter');
    const closeDrawer = () => {
        setDrawerIsOpening('exit')
        setTimeout(props.onClose,300)
    }

    useEffect(() => {
        setDrawerIsOpening('')
    },[])

    return (
        (props.visible && <div className={'customDrawer ' + (props.size || '') + ' ' + drawerIsOpening}>
            <div className="customDrawer__overlay" onClick={closeDrawer}></div>

           
            <div className={'customDrawer__content' + (props.loading ? ' loading ' : '')}>
                {props.title && <div className="customDrawer__title"><h1>{props.title}</h1><span className="customDrawer__title_closebutton"  onClick={closeDrawer}></span></div>}
                <div className="customDrawer__body">{props.children}</div>
                {props.loading && <div className="k-loading-image"/>}
                {/*props.loading ? <LoadingPanel/> :<div className="customDrawer__body">{props.children}</div>*/}
            </div>
        </div>)
    );
};

export default CustomDrawer;