import { REPLACE_OFFICES, UPDATE_OFFICE, ADD_OFFICE } from 'actions/office-grid-actions';

export const createReplaceOfficeAction = (offices) => {
  return { type: REPLACE_OFFICES, offices };
};

export const createUpdateOfficeAction = (officeId, officeUpdateData) => {
  return { type: UPDATE_OFFICE, officeId, officeUpdateData };
};

export const createAddOfficeAction = (office) => {
  return { type: ADD_OFFICE, office };
};