import React, { useState, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Form, Field } from "@progress/kendo-react-form";
import {
    Card,
    CardBody,
    CardHeader,
    StackLayout,
} from '@progress/kendo-react-layout';

import PagedTable from "./PagedTable";

const CRUDPage = (props) => {
    const showAddButton = typeof props.showAddButton == 'undefined' ? true : props.showAddButton
    const showRefreshButton = typeof props.showRefreshButton == 'undefined' ? true : props.showRefreshButton

    const onRowClick = (e) => {
        console.log(e)
        props.onRowClick(e)
    }

    const showCardHeader = showAddButton || showRefreshButton || props.tableFilters || props.custominfo
    return (
        <>
            <Card className="crud-page pageGrid">
                {showCardHeader && <CardHeader>
                    <StackLayout orientation="horizontal">
                        {showAddButton && <div style={{ textAlign: "left" }}>
                            <Button
                                type="button"
                                themeColor="primary"
                                onClick={props.onAddButtonClick}
                            >
                                Add
                            </Button>
                        </div>}
                        {props.custominfo}
                        <div style={{ textAlign: "right" }}>
                            <div className="pageGridToolbar">
                                <Form render={({ allowSubmit, onSubmit }) => (
                                    <form className="k-form table-filter-form " onSubmit={(e) => e.preventDefault()}>
                                        {props.tableFilters}
                                    </form>
                                )}
                                />

                                

                                {showRefreshButton && <Button
                                    type="button"
                                    themeColor="dark"
                                    onClick={props.onRefreshButtonClick}
                                >
                                    <span className="k-icon k-i-reload"></span> Refresh
                                </Button>}
                            </div>
                        </div>
                    </StackLayout>
                </CardHeader>}
                <CardBody>

                    <PagedTable dataresult={props.dataresult} onRowClick={onRowClick} initialSort={props.initialSort}>
                        {props.children}
                    </PagedTable>

                </CardBody>
            </Card>

        </>
    );
};

export default CRUDPage;