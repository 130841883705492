import { authFetch } from "api";

export const getCompetencyModels = async () => {
  const response = await authFetch('/api/competencymodel');
  if (response.ok) {
    const competencyModels = await response.json();
    return competencyModels;
  }
  throw response;
};

export const getCompetencyModel = async (competencyModelId) => {
  const url = '/api/competencymodel/' + competencyModelId;
  const response = await authFetch(url);
  if (response.ok) {
    const competencyModel = await response.json();
    return competencyModel;
  }
  throw response;
};

export const updateCompetencyModelName = async (CompetencyModelId, name) => {
  const url = '/api/competencymodel' + '?id=' + CompetencyModelId;
  const response = await authFetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({name}),
  });
};



export const updateCompetencyResource = async (competency) => {
  const url = '/api/competency/' + competency.competencyId + '/resource'
  const response = await authFetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(competency),
  });
};



export const publishCompetencyModel = async (competencyModelId) => {
  const url = '/api/competencymodel/' + competencyModelId + '/publish';
  const response = await authFetch(url, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};


export const closeCompetencyModel = async (competencyModelId, name) => {
  const url = '/api/competencymodel/' + competencyModelId + '/close';
  const response = await authFetch(url, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  });
};


export const addCompetencyModel = async (name) => {
  const url = '/api/competencymodel';
  const response = await authFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(name),
  });

  if (response.status === 201) {
    return response.json();
  }
  throw `Unhandled status code ${response.status} for addCompetencyModel`;

};


export const deleteCompetencyModel = async (competencyModelId) => {
  const url = '/api/competencymodel/' + competencyModelId

  const response = await authFetch(url, {
    method: 'DELETE'
  });

  if(!response.ok) {
    throw response;
  }

  return {}
};
