import { MultiSelect } from '@progress/kendo-react-dropdowns';
import CustomFieldWrapper from './CustomFieldWrapper';

const CustomMultiSelect = (props) => {
  const { validationMessage, visited, ...others } = props;
  //console.log(props.value);
  return (
    <CustomFieldWrapper validationMessage={validationMessage} visited={visited}>
      <MultiSelect {...others} />
    </CustomFieldWrapper>
  );
};

export default CustomMultiSelect;