const { Button } = require('@progress/kendo-react-buttons');

const RefreshButton = (props) => {
  return (
    <Button type="button" themeColor="dark" onClick={props.onClick}>
      <span className="k-icon k-i-reload"></span> Refresh
    </Button>
  );
};


export default RefreshButton;