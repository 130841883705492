export const validateMaxLength = (value, maxLength) => {
  return value.trim().length > maxLength ? `Max ${maxLength} characters` : '';
};

export const validateRequiredString = (value) => {
  return value === undefined || value === null || value.trim() === ''
    ? 'Required'
    : '';
};

export const validateRequiredArray = (value) => {
  return !Array.isArray(value) || value.length === 0 ? 'Required' : '';
};

export const validateRequiredObject = (value) => {
  return value === null ||
    value === undefined ||
    Object.keys(value).length === 0
    ? 'Required'
    : '';
};
