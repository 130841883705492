import { Hint } from '@progress/kendo-react-labels';

const LengthCounter = (props) => {
  const { current, max, ...others } = props;
  return (
    <Hint direction="end" {...others}>
      {current} / {max}
    </Hint>
  );
};

export default LengthCounter;
