import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import BkoUserGrid from 'components/bkoUser/BkoUserGrid';
import LoadingPanel from 'components/shared/LoadingPanel';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { DEFAULT_PAGE_SIZE } from 'settings/grid-settings';
import {
    StackLayout,
} from '@progress/kendo-react-layout';
import { getBkoUsers } from 'api/bkoUsers-api';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import { ADD_BKOUSER_ROUTE, EDIT_BKOUSER_ROUTE, LIST_BKOUSER_ROUTE } from 'routes';
import { getOffices } from "api/offices";

import bkoUsersReducer from 'reducers/bkoUser-grid-reducer';
import {
    createAddBkoUserAction,
    createUpdateBkoUserAction,
    createReplaceBkoUserAction,
} from 'action-creators/bkoUser-grid-action-creators';
import useNotifications from 'hooks/use-notifications';
import NewBkoUserForm from 'components/bkoUser/NewBkoUserForm';
import EditBkoUserForm from 'components/bkoUser/EditBkoUserForm';

import { createErrorNotification } from 'utils/notifications-creator';
import PageTitle from 'components/layout/PageTitle';
import RightDrawer from 'components/layout/RightDrawer';
import { ComboBox } from "@progress/kendo-react-dropdowns";

// TODO: move into a utils file
const paginate = (items, skip, take) => {
    return items.slice(skip, take + skip);
};

const initialPageState = {
    skip: 0,
    take: DEFAULT_PAGE_SIZE,
};

const BkoUserPage = (props) => {
    const [loading, setLoading] = useState(true);
    const [bkoUsers, dispatchBkoUsersAction] = useReducer(bkoUsersReducer, []);
    const [page, setPage] = useState(initialPageState);
    const [bkoUsersData, setBkoUsersData] = useState([]);
    const [offices, setOffices] = useState([]);    

    const [currentBkoUser, setCurrentBkoUser] = useState(null);    
    

    const history = useHistory();
    const notifications = useCallback(useNotifications, []); //();

    const fetchBkoUsers = useCallback(async () => {
        console.log('fetching bkoUsers...');
        setLoading(true);
        try {
            const bkoUsers = await getBkoUsers();
            const offices = await getOffices();
            setOffices(offices)

            setBkoUsersData(bkoUsers.map(p => {
                let bkoUserOffice = offices.find(c => {
                    return c.officeId == p.officeId
                })
                let bkoUser = { ...p, officeName: bkoUserOffice?.officeName }
                return bkoUser
            }))
            
            dispatchBkoUsersAction(createReplaceBkoUserAction(bkoUsersData));
        } catch (err) {
            console.error(err)
            notifications.push(createErrorNotification(err.message));
        } finally {
            setLoading(false);
        }
    }, [notifications]);


    const pageChangeHandler = (e) => {
        console.debug('page size changed: ' + JSON.stringify(e.page));
        setPage(e.page);
    };

    /*const bkoUserAddedHandler = (bkoUser) => {
        dispatchBkoUsersAction(createAddBkoUserAction(bkoUser));
    };*/

    const bkoUserUpdatedHandler = (adminId, submittedFormData) => {
        const updateAction = createUpdateBkoUserAction(adminId, submittedFormData);
        dispatchBkoUsersAction(updateAction);
        fetchBkoUsers();
    };

    const addButtonClickHandler = () => {
        history.push(ADD_BKOUSER_ROUTE);
    };

    const refreshButtonClickHandler = () => {
        fetchBkoUsers();
    };

    const cancelClickHandler = () => {
        history.replace(LIST_BKOUSER_ROUTE);
    };

    const editButtonClickHandler = (bkoUser) => {
        console.log('edit clicked -> ' + bkoUser.adminId);
        setCurrentBkoUser(bkoUser)
        history.push(EDIT_BKOUSER_ROUTE.replace(':adminId', bkoUser.adminId));
    };

    useEffect(() => {
        console.log('useEffect running on BkoUserPage');
        fetchBkoUsers();
    }, [fetchBkoUsers]);

    const [state, setState] = useState({
        globalFilter: "",
        officeFilter: null
    })


    const handleChange = (evt) => {
        const value = evt.value;
        setState({
            ...state,
            [evt.target.props.name]: value
        });
        setPage(Object.assign({}, initialPageState, { page: 1 }));
    }

    const handleChangeOfficeFilter = (event) => {
        setState({
            ...state,
            officeFilter: event.target.value,
        });
        setPage(Object.assign({}, initialPageState, { page: 1 }));
    };
    

    const customFilterData = (e) => {
        return e?.filter(o => {
            return (state.officeFilter == null || o.officeId == state.officeFilter?.officeId)
                &&
                (state.globalFilter == '' || state.globalFilter != '' && (o.email.toLowerCase().indexOf(state.globalFilter.toLowerCase()) != -1 ||
                    (o.officeName || '').toLowerCase().indexOf(state.globalFilter.toLowerCase()) != -1))

        })
    }

    const filteredBkoUsers = customFilterData(bkoUsersData)
    // console.log('filteredBkoUsers',filteredBkoUsers)
    const visibleBkoUsers = paginate(filteredBkoUsers, page.skip, page.take);
    //console.log('visibleBkoUsers',visibleBkoUsers)
    const location = useLocation();
    const drawerExpanded =
        location.pathname.includes('new') || location.pathname.includes('edit');



    const filterField = <div>

        <Form render={({ allowSubmit, onSubmit }) => (
            <form className="k-form table-filter-form " style={{ display: 'flex', gap: '15px', width: '450px' }} onSubmit={(e) => e.preventDefault()}>

                <Field name="globalFilter" label="Search" component={Input} onChange={handleChange} />

                <ComboBox
                    data={offices}
                    textField="officeName"
                    dataItemKey="officeId"
                    value={state.officeFilter}
                    onChange={handleChangeOfficeFilter}
                    label="Office"
                    name="Office"

                />


            </form>
        )}
        />

    </div>





    /*const gridData = (e) => {
        //return customFilterData(orderBy(dataResult, sort)).slice(page.skip, page.take + page.skip)
        return customFilterData(orderBy(dataResult, sort)).slice(page.skip, page.take + page.skip)
    }*/

    return (
        <section>
            {loading && <LoadingPanel />}

            <StackLayout orientation="vertical" gap="1em">
                <PageTitle text="Atrain Backoffice Users" />

                <BkoUserGrid
                    bkoUsers={visibleBkoUsers}
                    totalBkoUsers={filteredBkoUsers.length}
                    skip={page.skip}
                    take={page.take}
                    
                    onPageChange={pageChangeHandler}
                    onAddButtonClick={addButtonClickHandler}
                    onRefreshButtonClick={refreshButtonClickHandler}
                    onEditButtonClick={editButtonClickHandler}
                    filterField={filterField}
                />
            </StackLayout>
            <RightDrawer expanded={drawerExpanded}>
                <Switch>
                    {/*<Route path={ADD_BKOUSER_ROUTE}>
                        <NewBkoUserForm
                            onBkoUserAdded={bkoUserAddedHandler}
                            onAddBkoUserCancel={cancelClickHandler}
                        />
    </Route>*/}
                    <Route path={EDIT_BKOUSER_ROUTE}>
                        <EditBkoUserForm
                            bkoUser={currentBkoUser}
                            onBkoUserUpdated={bkoUserUpdatedHandler}
                            onBkoUserUpdateCancel={cancelClickHandler}
                        />
                    </Route>
                </Switch>
            </RightDrawer>
        </section>
    );
};

export default BkoUserPage;
