import React, { useState, useEffect, useCallback } from "react";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from 'routes';
import { useHistory } from "react-router-dom";
import { Form, Field } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import {StackLayout } from '@progress/kendo-react-layout';
import LoadingPanel from 'components/shared/LoadingPanel';
import { DropDownList  } from "@progress/kendo-react-dropdowns";

import PageTitle from 'components/layout/PageTitle';
import CRUDPage from 'components/shared/CRUDPage';

import CompetencyModelDetail from "components/competencyModel/CompetencyModelDetail";
import CompetencyModelCreate from "components/competencyModel/CompetencyModelCreate";
import CompetencyModelStatusLabel from "components/competencyModel/CompetencyModelStatusLabel";
//import EditCommandCell from "components/shared/EditCommandCell";

import ActionGridColumn from "components/competencyModel/ActionGridColumn";
import useNotifications from 'hooks/use-notifications';
import {
    createSuccessNotification,
    createErrorNotification,
  } from 'utils/notifications-creator';

import { getCompetencyModel, updateCompetencyModelName,publishCompetencyModel, closeCompetencyModel, deleteCompetencyModel } from "api/competency-models";
import { actionPublishCompetency, actionCloseCompetency, actionDeleteCompetency } from "components/competencyModel/actions";
import { getAssessmentByProjectId, resetAssessment } from "api/assessment";
import StatusGridColumn from "components/assessment/StatusGridColumn";


const EditCommandCell = (props) => {
    return (<td><Button themeColor="tertiary" disabled={props.disabled} onClick={props.onClick} size={props.size}>{props.text}</Button></td>)
 };

const initialSort = [
    {
        field: "clientName",
        dir: "asc",
    },
];

const DateCell = (props) => {
    const formattedDate = props.value ? new Date(props.value).toLocaleString() : '-'
    return (<td>{formattedDate}</td>)
}

const AssessmentPage = (props) => {
    const history = useHistory();
    const [state, setState] = React.useState({
        globalFilter: "",
        assessmentType:{id:''}
    })
    const [refresh, setRefresh] = React.useState(0);
    const [loading, setLoading] = useState(true);
    const [assessmentTypes, setAssessmentTypes] = useState([{id:'', label:'All'},{id:'S', label:'Self'},{id:'F', label:'Feedback'}]);

    const notifications = useNotifications();

    const onBackButtonClick = (e) => {
        history.push(ROUTES.PROJECTS.EDIT.replace(':projectId', props.match.params.projectId));
    }

    const onResetButtonClick = async (e) => {
        console.log(e.assessmentId)

        console.log('Reset clicked on assessment "' + e.assessmentId + '"');
        const actionConfirmed = window.confirm(
          `Are you sure you want to reset this assessment?`
        );
        if (actionConfirmed) {
          setLoading(true);
          try {
            await resetAssessment(e.assessmentId, e.personId);
            notifications.push(
              createSuccessNotification(
                `The assessment has been reset successfully!`
              )
            );
            setRefresh(refresh+1)
          } catch (errorResponse) {
            notifications.push(
              createErrorNotification(
                `We're sorry! We couldn't reset the assessment". Server response: ${errorResponse.status}`
              )
            );
          }
          setLoading(false);
        }
      };

    const handleChange = (evt) => {
        const value = evt.value;
        setState({
            ...state,
            [evt.target.props.name]: value
        });

    }

    let [dataResult, setDataResult] = useState([])


    useEffect(() => {
        fetchData()
      }, [refresh])


      const fetchData = useCallback(async () => {
        console.log('fetching data...');
        setLoading(true);
        try {
            const data = await getAssessmentByProjectId(props.match.params.projectId);
            setDataResult(data)
        } catch (err) {
          notifications.push(createErrorNotification(err.message));
        } finally {
          setLoading(false);
        }
      }, [notifications, refresh]);


      
      const handleChangeAssessmentTypeFilter = (event) => {
        setState({
            ...state,
            assessmentType: event.target.value,
        });

        console.log(event.target.value)
    };

    const customFilterData = () => {
        if(dataResult?.assessments){
            return dataResult?.assessments?.filter(o => {
                return (o.focusPersonFullName.toLowerCase().indexOf(state.globalFilter.toLowerCase()) != -1) 
                    &&
                    (state.assessmentType?.id == '' || state.assessmentType?.id == o.assessmentType)
            })
        }else{
            return []
        }
        
    }

    const tableFilters = <div className="form-filter">
        <Field name="globalFilter" label="Search" component={Input} onChange={handleChange}/>
    
        <DropDownList
                    data={assessmentTypes}
                    textField="label"
                    dataItemKey="id"
                    value={state.assessmentType}
                    onChange={handleChangeAssessmentTypeFilter}
                    label="Assessment type"
                    name="Type"
                    

                />
    </div>

    const customInfo = <div style={{ textAlign: "left", display:'flex', alignItems:'center'}}>
            <b>Client:</b> {dataResult.clientName}&nbsp;&nbsp;&nbsp;&nbsp;<b>Project:</b> {dataResult.projectName}
    </div>

    const backtoproj = <div style={{float:'right'}}><Button fillMode="flat" icon="arrow-chevron-left" onClick={onBackButtonClick}>Back to project</Button></div>

    return (
        <>
            <section className="assessmentPage">
                {loading && <LoadingPanel/>}
                <StackLayout orientation="vertical" gap="1em">
                    <PageTitle text="Atrain Assessments" customitems={backtoproj}/>

                    <CRUDPage
                        dataresult={customFilterData()}
                        tableFilters={tableFilters}
                        initialSort={initialSort}
                        onRowClick={(e)=>{}}
                        showAddButton={false}
                        showRefreshButton={false}
                        custominfo={customInfo}
                    >


                        <GridColumn field="assessmentId" title="Assessment ID" width={140} />
                        <GridColumn field="assessmentTypeName" title="Assessment Type" />
                        <GridColumn field="focusPersonFullName" title="Focus Person" />
                        <GridColumn field="personFullName" title="Person" />
                        
                        <GridColumn field="sessionStatusName" title="Session status"  cell={(props) => <StatusGridColumn props={props}/>} width="150" />

                        
                        
                        {<GridColumn cell={(props) => <EditCommandCell text={'Reset'} onClick={()=>{onResetButtonClick(props.dataItem)}}  size="small" disabled={!props.dataItem?.resettable} props={props} />} width="130" />}

                        

                        {/*<GridColumn
                         width={90} 
                        cell={(cellProps) => (
                        <ActionGridColumn
                            {...cellProps}
                            showEdit={true}
                            showDelete={cellProps.dataItem.status == 'D'}
                            showPublish={cellProps.dataItem.status == 'D'}
                            showClose={cellProps.dataItem.status == 'A'}

                            onEditClick={()=>{onEditButtonClick(cellProps.dataItem)}}
                            onDeleteClick={()=>{onDeleteCompetency(cellProps.dataItem)}}
                            onPublishClick={()=>{onPublishCompetency(cellProps.dataItem)}}
                            onCloseClick={()=>{onCloseCompetency(cellProps.dataItem)}}
                        />
                        )}
                    />*/}
            

                    </CRUDPage>
                   
                    

                    {/*<Switch>
                        <Route path={ROUTES.COMPETENCY_MODEL.ADD} render={(props) => <CompetencyModelCreate {...props} onChange={()=>{setRefresh(refresh + 1)}} />}/>
                        <Route path={ROUTES.COMPETENCY_MODEL.EDIT} render={(props) => <CompetencyModelDetail {...props} onChange={()=>{setRefresh(refresh + 1)}} />}/>
                        </Switch>*/}

                </StackLayout>
            </section>
        </>
    );
};

export default AssessmentPage;