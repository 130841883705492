import { createAddClientAction } from 'action-creators/client-grid-action-creators';
import { createClient } from 'api/clients';
import { getClients } from "api/clients";
import ClientForm from 'components/client/ClientForm';
import LoadingPanel from 'components/shared/LoadingPanel';
import useNotifications from 'hooks/use-notifications';
import { useCallback, useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router';
import { LIST_CLIENT_ROUTE } from 'routes';
import { createErrorNotification, createSuccessNotification } from 'utils/notifications-creator';
import UserInfoContext from 'stores/user-context';


const NewClientForm = (props) => {
  const userInfo = useContext(UserInfoContext);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    clientName: '',
    offices:props.offices,
    officeId:userInfo.officeId || props.offices[0].officeId
  });



  const notifications = useNotifications();
  const history = useHistory();

  const submitClickHandler = async (submittedClient) => {
    const clientData = {
      clientName: submittedClient.clientName.trim(),
      officeId: submittedClient.officeId,
      officeName:submittedClient.officeName,
    };

    setLoading(true);

    try {
      const client = await createClient(clientData);
      // dispatchClientsAction(createAddClientAction(client));
      notifications.push(
        createSuccessNotification('Client created successfully.')
      );
      props.onClientAdded?.(client);
      history.replace(LIST_CLIENT_ROUTE);
      // TODO: navigate to the last page?
    } catch (err) {
      notifications.push(
        createErrorNotification('Something was wrong saving the client.')
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchClientFormData = useCallback(
    async () => {
      setLoading(true);
      try {
        const clients = await getClients();

        setFormData({
          clients:clients,
          offices:props.offices
        });
      } catch (err) {
        notifications.push({ type: 'error', text: err.message });
        history.push(LIST_CLIENT_ROUTE);
      } finally {
        setLoading(false);
      }
    },
    [history, notifications,props.offices]
  );

  useEffect(() => {
    fetchClientFormData();
  }, [fetchClientFormData]);


  return (
    <>
    
      {loading && <LoadingPanel />}
      <ClientForm
        data={formData}
        onSubmitClick={submitClickHandler}
        onCancelClick={props.onAddClientCancel}
      />
    </>
  );
};

export default NewClientForm;
