const { Button } = require('@progress/kendo-react-buttons');

const AddButton = (props) => {
  return (
    <Button type="button" themeColor="primary" onClick={props.onClick}>
      Create {props.entityName}
    </Button>
  );
};

export default AddButton;
