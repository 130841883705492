import { getMultipleResource, updateResource } from 'api/resources';
import { getClients } from "api";
import LoadingPanel from 'components/shared/LoadingPanel';
import useNotifications from 'hooks/use-notifications';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { LIST_RESOURCE_ROUTE } from 'routes';
import {
  createSuccessNotification,
  createErrorNotification,
} from 'utils/notifications-creator';
import ResourceForm from './ResourceForm';

const EditResourceForm = (props) => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState();

  const params = useParams();
  const resourceIds = params.resourceIds.split();

  const resourceId = null;


  const notifications = useNotifications();
  const history = useHistory();

  const submitClickHandler = async (submittedFormData) => {
    console.log('submittedFormData>', submittedFormData)

    const p = submittedFormData.values.map(v => {
      return updateResource(v.resourceId, v);
    })
    console.log(p);

    try {

      await Promise.all(p);
      //await updateResource(resourceId, resourceData);
      notifications.push(
        createSuccessNotification('Resource updated successfully.')
      );
      props.onResourceUpdated?.(resourceId, {submittedFormData});
      history.replace(LIST_RESOURCE_ROUTE);
    } catch (err) {
      notifications.push(
        createErrorNotification('Something was wrong updating the resource.')
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchResourceFormData = useCallback(
    async () => {
      setLoading(true);
      try {
        console.log('getMultipleResource',resourceIds)
        const resource = await getMultipleResource(resourceIds);
        //const clients = await getClients();

        
        let fullFormData = {
          resourceKey:resource[0].resourceKey,
          values:[]
        }

        fullFormData.values = resource.map(r=>{
          return {
            ...r
          }
        })
        
        console.log('fullFormData',fullFormData)

        setFormData(fullFormData);

      } catch (err) {
        notifications.push({ type: 'error', text: err.message });
        history.push(LIST_RESOURCE_ROUTE);
      } finally {
        setLoading(false);
      }
    },
    [history, notifications]
  );

  useEffect(() => {
    fetchResourceFormData();
  }, [fetchResourceFormData]);

  return loading ? (
    <LoadingPanel />
  ) : (
    <ResourceForm
      data={formData}
      onSubmitClick={submitClickHandler}
      onCancelClick={props.onResourceUpdateCancel}
    />
  );
};

export default EditResourceForm;
