import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import {
  Card,
  CardBody,
  CardHeader,
  StackLayout,
} from '@progress/kendo-react-layout';
import { PAGE_SIZES } from 'settings/grid-settings';


const ResourceGrid = (props) => {
  const editClickHandler = (rowData) => {
  
    props.onEditButtonClick(rowData);
  };

  console.log('props.resources',props.resources)

  return (
    <Card className="resourceGrid pageGrid">
      <CardHeader>
        <StackLayout orientation="horizontal">
          {/*<div style={{textAlign:"left"}}>
            <Button
              type="button"
              themeColor="primary"
              onClick={props.onAddButtonClick}
              className="btnAdd"
            >
              Add Resource
            </Button>
  </div>*/}
          <div style={{textAlign:"right"}}>
              <div className='resourceGridToolbar pageGridToolbar'>
              {props.filterField}


              <Button
                type="button"
                themeColor="dark"
                onClick={props.onRefreshButtonClick}
              >
                <span className="k-icon k-i-reload"></span> Refresh
              </Button>
              </div>
         
          </div>
        </StackLayout>
      </CardHeader>
      <CardBody>
      
        <Grid
          id={props.id}
          data={props.resources}
          total={props.totalResources}
          pageable={{ type: 'input', pageSizes: PAGE_SIZES }}
          skip={props.skip}
          take={props.take}
          onPageChange={props.onPageChange}
        >
          <GridColumn field="resourceKey" title="Resource Key" />


          {props.resources[0]?.listResources.map((item,i) => <GridColumn
            key={i}
            title={item.cultureCode}
              cell={(props) => (
                <td>{props.dataItem.listResources[i].resourceValue}</td>
              )}
            />
          )}
          
          

          <GridColumn
            
            cell={(props) => (
              <td>
                <Button                  
                  type="button"
                  themeColor="tertiary"
                  size="small"
                  onClick={editClickHandler.bind(null, props.dataItem)}
                >
                  Edit
                </Button>
              </td>
            )}
          />
        </Grid>
      </CardBody>
    </Card>
  );
};

export default ResourceGrid;
