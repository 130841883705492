import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import ResourceGrid from 'components/resource/ResourceGrid';
import LoadingPanel from 'components/shared/LoadingPanel';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { DEFAULT_PAGE_SIZE } from 'settings/grid-settings';
import {
    StackLayout,
} from '@progress/kendo-react-layout';
import { getResources, getResourceGroups } from 'api/resources';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import { ADD_RESOURCE_ROUTE, EDIT_RESOURCE_ROUTE, LIST_RESOURCE_ROUTE } from 'routes';

import resourcesReducer from 'reducers/resource-grid-reducer';
import {
    createAddResourceAction,
    createUpdateResourceAction,
    createReplaceResourceAction,
} from 'action-creators/resource-grid-action-creators';
import useNotifications from 'hooks/use-notifications';
import NewResourceForm from 'components/resource/NewResourceForm';
import EditResourceForm from 'components/resource/EditResourceForm';

import { createErrorNotification } from 'utils/notifications-creator';
import PageTitle from 'components/layout/PageTitle';
import RightDrawer from 'components/layout/RightDrawer';
import { ComboBox } from "@progress/kendo-react-dropdowns";

// TODO: move into a utils file
const paginate = (items, skip, take) => {
    return items?.slice(skip, take + skip);
};

const initialPageState = {
    skip: 0,
    take: DEFAULT_PAGE_SIZE,
};

const ResourcePage = (props) => {
    const [loading, setLoading] = useState(true);
    const [init, setInit] = useState(false);
    const [resources, dispatchResourcesAction] = useReducer(resourcesReducer, []);
    const [page, setPage] = useState(initialPageState);
    //const [groupName, setGroupName] = useState('Global');
    const [resourceGroups, setResourceGroups] = useState([]);
    const [refresh, setRefresh] = useState(0);

    const history = useHistory();
    const notifications = useCallback(useNotifications, []); //();

    const [state, setState] = useState({
        globalFilter: "",
        resourceGroupFilter: 'Global'
    })


    const fetchResources = useCallback(async (fetchGroups) => {
        console.log('fetching resources...');
        setLoading(true);
        try {


            let resources = await getResources(state.resourceGroupFilter);
           
            dispatchResourcesAction(createReplaceResourceAction(resources));

            setInit(true)
        } catch (err) {
            console.error(err)
            notifications.push(createErrorNotification(err.message));
        } finally {
            setLoading(false);
        }
    }, [notifications,state.resourceGroupFilter, refresh]);


    const pageChangeHandler = (e) => {
        console.debug('page size changed: ' + JSON.stringify(e.page));
        setPage(e.page);
    };

    const resourceAddedHandler = (resource) => {
        dispatchResourcesAction(createAddResourceAction(resource));
    };

    const resourceUpdatedHandler = (resourceId, submittedFormData) => {
        const updateAction = createUpdateResourceAction(resourceId, submittedFormData);
        dispatchResourcesAction(updateAction);
        setRefresh(refresh + 1)
    };

    const addButtonClickHandler = () => {
        history.push(ADD_RESOURCE_ROUTE);
    };

    const refreshButtonClickHandler = () => {
        fetchResources();
    };

    const cancelClickHandler = () => {
        history.replace(LIST_RESOURCE_ROUTE);
    };

    const editButtonClickHandler = (rowData) => {
        //listResources[i].resourceValue
        console.log('editButtonClickHandler clicked -> ', rowData);
        const ids = rowData.listResources.map(i=>i.resourceId).join()
        console.log('edit clicked -> ', ids);
        history.push(EDIT_RESOURCE_ROUTE.replace(':resourceIds', ids));
    };

    useEffect(() => {
        console.log('useEffect running on ResourcePage');
        fetchResources(true);
    }, [fetchResources, state.resourceGroupFilter]);

  
    useEffect(() => {
        console.log('useEffect running getResourceGroups');

        
        const fetchData = async () => {
            const resourceGroups = await getResourceGroups()
            setResourceGroups(resourceGroups.map(function(elem){
                return elem.resourceGroup;
            }))
          }
          fetchData()
          
        
    }, [])

    


    const handleChange = (evt) => {
        const value = evt.value;
        setState({
            ...state,
            [evt.target.props.name]: value
        });
        setPage(Object.assign({}, initialPageState, { page: 1 }));

    }

    const handleChangeResourceGroupFilter = (event) => {
       
        
        setState({
            ...state,
            resourceGroupFilter: event.target.value,
        });
        setPage(Object.assign({}, initialPageState, { page: 1 }));
        //setTimeout( ()=> {fetchResources(); console.log('handleChange',state.resourceGroupFilter)},1)
    };


    const customFilterData = (e) => {
        if(!e)
            return []
        return e?.filter(o => {
            return (state.globalFilter == '' || state.globalFilter != '' && (
                    
                o.resourceKey.toLowerCase().indexOf(state.globalFilter.toLowerCase()) != -1
                
                ||

                o.listResources?.some(r => r.resourceValue.toLowerCase().indexOf(state.globalFilter.toLowerCase()) != -1)

                )
                    
                    )

        })
    }

    const filteredResources = customFilterData(resources)
    // console.log('filteredResources',filteredResources)
    const visibleResources = paginate(filteredResources, page.skip, page.take);
    //console.log('visibleResources',visibleResources)
    const location = useLocation();
    const drawerExpanded =
        location.pathname.includes('new') || location.pathname.includes('edit');



    const filterField = 

        <Form render={({ allowSubmit, onSubmit }) => (
            <form className="k-form table-filter-form " style={{ display: 'flex', gap: '15px', width: '450px' }} onSubmit={(e) => e.preventDefault()}>

                <Field name="globalFilter" label="Search" component={Input} onChange={handleChange} />

                <ComboBox
                    data={resourceGroups}
                  
                    value={state.resourceGroupFilter}
                    onChange={handleChangeResourceGroupFilter}
                    label="Resource group"
                    name="resourceGroup"

                />

                

            </form>
        )}
        />






    /*const gridData = (e) => {
        //return customFilterData(orderBy(dataResult, sort)).slice(page.skip, page.take + page.skip)
        return customFilterData(orderBy(dataResult, sort)).slice(page.skip, page.take + page.skip)
    }*/

    return (
        <section>
            {loading && <LoadingPanel />}

            <StackLayout orientation="vertical" gap="1em">
                <PageTitle text="Atrain Resources" />

                {init && <ResourceGrid
                    resources={visibleResources}
                    totalResources={filteredResources.length}
                    skip={page.skip}
                    take={page.take}
                    onPageChange={pageChangeHandler}
                    onAddButtonClick={addButtonClickHandler}
                    onRefreshButtonClick={refreshButtonClickHandler}
                    onEditButtonClick={editButtonClickHandler}
                    filterField={filterField}
                />}
            </StackLayout>
            <RightDrawer expanded={drawerExpanded}>
                <Switch>
                    <Route path={ADD_RESOURCE_ROUTE}>
                        <NewResourceForm
                            onResourceAdded={resourceAddedHandler}
                            onAddResourceCancel={cancelClickHandler}
                        />
                    </Route>
                    <Route path={EDIT_RESOURCE_ROUTE}>
                        <EditResourceForm
                            onResourceUpdated={resourceUpdatedHandler}
                            onResourceUpdateCancel={cancelClickHandler}
                        />
                    </Route>
                </Switch>
            </RightDrawer>
        </section>
    );
};

export default ResourcePage;
