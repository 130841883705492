import {
  getClientOptions,
  getCompetencyModelOptions,
  getLanguageOptions,
  //getOfficeOptions,
  getPersonOptionsByClientId,
} from 'api/dropdown-values';
import { getProjectById, updateProject } from 'api/projects';
import ProjectForm from 'components/projects/ProjectForm';
import LoadingPanel from 'components/shared/LoadingPanel';
import useAzureADAccessToken from 'hooks/use-azure-ad-access-token';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';

const EditProjectForm = (props) => {
  // console.log('EditProjectForm component is running...');

  const [projectFormData, setProjectFormData] = useState(null);
  const [resources, setResources] = useState(null);
  const [loadingResources, setLoadingResources] = useState(true);

  const accessToken = useAzureADAccessToken();
  // console.log(`accessToken is set? ${typeof accessToken}`);

  const params = useParams();
  const projectId = parseInt(params.projectId);
  const fetchResources = useCallback(async (accessToken) => {
    // console.log('fetching resources with Bearer: ' + accessToken);
    const resources = await Promise.all([
      getCompetencyModelOptions(),
      getLanguageOptions(),
      getClientOptions(),
      //getOfficeOptions(accessToken),
      getProjectById(projectId),
    ]);

    const competencyModels = resources[0];
    const languages = resources[1];
    const clients = resources[2];
    //const offices = resources[3];
    const project = resources[3];
    const persons = await getPersonOptionsByClientId(project.clientId);

    //console.log(persons);
    //console.log(project);

    const selectedClient = { id: project.clientId, name: project.clientName };
    const selectedPersons = project.persons.map((p) => ({
      id: p.personId,
      fullName: p.fullName,
    }));
    const selectedCompetencyModel = {
      id: project.competencyModelId,
      name: project.competencyModelName,
    };
    /*const selectedOfficeInCharge = {
      id: project.officeInChargeId,
      name: project.officeInChargeName,
    };*/
    const selectedLanguage = languages.find(
      (l) => l.cultureCode === project.cultureCode
    );

    const projectFormData = {
      projectId:project.projectId,
      projectName: project.projectName,
      projectDescription: project.projectDescription || '',
      client: selectedClient,
      persons: selectedPersons,
      competencyModel: selectedCompetencyModel,
      //officeInCharge: selectedOfficeInCharge,
      language: selectedLanguage,
      status:project.status,
      customMessage:project.customMessage
    };

    // console.log(projectFormData);

    setResources({
      competencyModels,
      languages,
      clients,
      //offices,
      persons,
    });

    setProjectFormData(projectFormData);
    setLoadingResources(false);
  }, [projectId]);

  useEffect(() => {
    // console.log(
    //   'EditProjectForm -> useEffect is running to fetch resources...'
    // );
    if(accessToken) {
      fetchResources(accessToken);
    }
  }, [accessToken, fetchResources]);

  const submitClickHandler = async (submittedProject) => {
    props.onUpdating?.(submittedProject);

    try {
      await updateProject(projectId, submittedProject);
      const updatedProjectData = {
        projectId: projectId,
        projectName: submittedProject.projectName,
        clientName: resources.clients.find(
          (c) => c.id === submittedProject.clientId
        )?.name,
        cultureCode: resources.languages.find(
          (l) => l.cultureCode === submittedProject.cultureCode
        )?.cultureCode,
        personCount: submittedProject.persons.length,
      };
      props.onUpdated?.(updatedProjectData);
    } catch (errorResponse) {
      console.error(errorResponse);
      props.onUpdateFailed?.(errorResponse);
    }
  };

  const cancelClickHandler = () => {
    props.onUpdateCancel?.();
  };

  return loadingResources ? (
    <LoadingPanel />
  ) : (
    <ProjectForm
      title="Edit Project"
      initialValues={projectFormData}
      onSubmit={submitClickHandler}
      onCancel={cancelClickHandler}
      onPublishProject={(e) =>{props.onPublishProject(e)}}
      onCloseProject={(e) =>{props.onCloseProject(e)}}
      onDeleteProject={(e) =>{props.onDeleteProject(e)}}
      onViewAssessments={(e) =>{props.onViewAssessments(e)}}
      onViewInvitees={(e) =>{props.onViewInvitees(e)}}
      
      
      {...resources}
    />
  );
};

export default EditProjectForm;
