import React, { useState, useEffect, useCallback } from "react";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from 'routes';
import { useHistory } from "react-router-dom";
import { Form, Field } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import {StackLayout } from '@progress/kendo-react-layout';
import LoadingPanel from 'components/shared/LoadingPanel';

//import { getClients } from "api/clients";
import { getCompetencyModels } from 'api/competency-models';

import PageTitle from 'components/layout/PageTitle';
import CRUDPage from 'components/shared/CRUDPage';

import CompetencyModelDetail from "components/competencyModel/CompetencyModelDetail";
import CompetencyModelCreate from "components/competencyModel/CompetencyModelCreate";
import CompetencyModelStatusLabel from "components/competencyModel/CompetencyModelStatusLabel";
import EditCommandCell from "components/shared/EditCommandCell";

import ActionGridColumn from "components/competencyModel/ActionGridColumn";
import useNotifications from 'hooks/use-notifications';
import {
    createSuccessNotification,
    createErrorNotification,
  } from 'utils/notifications-creator';

import { getCompetencyModel, updateCompetencyModelName,publishCompetencyModel, closeCompetencyModel, deleteCompetencyModel } from "api/competency-models";
import { actionPublishCompetency, actionCloseCompetency, actionDeleteCompetency } from "components/competencyModel/actions";

const initialSort = [
    {
        field: "clientName",
        dir: "asc",
    },
];

const DateCell = (props) => {
    const formattedDate = props.value ? new Date(props.value).toLocaleString() : '-'
    return (<td>{formattedDate}</td>)
}

const CompetencyModelPage = (props) => {
    const history = useHistory();
    const [state, setState] = React.useState({
        globalFilter: ""
    })
    const [refresh, setRefresh] = React.useState(0);
    const [loading, setLoading] = useState(true);

    const notifications = useNotifications();

    
    const onPublishCompetency = async (competencyModel) => {
        actionPublishCompetency(competencyModel.id, notifications)
        
    };

    const onCloseCompetency = async (competencyModel) => {
        actionCloseCompetency(competencyModel.id, notifications)
    };

    const onDeleteCompetency = async (competencyModel) => {
        actionDeleteCompetency(competencyModel.id, notifications)
    };
    



    const onEditButtonClick = (e) => {
        console.log('onEditButtonClick',e)
        goToDetail(e.id)
    }

    const handleChange = (evt) => {
        const value = evt.value;
        setState({
            ...state,
            [evt.target.props.name]: value
        });

    }

    let [dataResult, setDataResult] = useState([])


    useEffect(() => {
        fetchData()
      }, [refresh])


      const fetchData = useCallback(async () => {
        console.log('fetching data...');
        setLoading(true);
        try {
            const data = await getCompetencyModels();
            setDataResult(data)
        } catch (err) {
          notifications.push(createErrorNotification(err.message));
        } finally {
          setLoading(false);
        }
      }, [notifications, refresh]);


    const goToDetail = (id) => {
        history.push(ROUTES.COMPETENCY_MODEL.EDIT.replace(':competencyModelId', id));
    }

    const goToCreate = (id) => {
        history.push(ROUTES.COMPETENCY_MODEL.ADD);
    }

    const onRowClick = (e) => {
        console.log('onRowClick', e)
        goToDetail(e.dataItem.id)
    }

    const customFilterData = () => {
        return dataResult.filter(o => o.name.toLowerCase().indexOf(state.globalFilter.toLowerCase()) != -1 || o.id == state.globalFilter)
    }

    const tableFilters = <div><Field name="globalFilter" label="Search" component={Input} onChange={handleChange} /></div>

    return (
        <>
            <section>
                {loading && <LoadingPanel/>}
                <StackLayout orientation="vertical" gap="1em">
                    <PageTitle text="Atrain Competency models" />

                    <CRUDPage
                        dataresult={customFilterData()}
                        tableFilters={tableFilters}
                        initialSort={initialSort}
                        onRowClick={onRowClick}
                        onAddButtonClick={goToCreate}
                        showRefreshButton={false}
                    >
                        <GridColumn field="id" title="ID" width={120} />
                        <GridColumn field="name" title="Name" />

                        
                        <GridColumn filterable={false} title="Status" field="status" cell={(props) => <td>{  <CompetencyModelStatusLabel status={props.dataItem.status}/>}</td>} width="140" />
                        {/*<GridColumn filterable={false} title="Created" field="createdDate" cell={(props) => <DateCell value={props.dataItem.createdDate} props={props} />} width="200" />*/}
                        {/*<GridColumn cell={(props) => <EditCommandCell props={props} />} width="150" />*/}

                        <GridColumn cell={(props) => <EditCommandCell text={'Edit'} onClick={()=>{onEditButtonClick(props.dataItem)}}  size="small" props={props} />} width="120" />

                        

                        <GridColumn
                         width={90} 
                        cell={(cellProps) => (
                        <ActionGridColumn
                            {...cellProps}
                            showEdit={true}
                            showDelete={cellProps.dataItem.status == 'D'}
                            showPublish={cellProps.dataItem.status == 'D'}
                            showClose={cellProps.dataItem.status == 'A'}

                            onEditClick={()=>{onEditButtonClick(cellProps.dataItem)}}
                            onDeleteClick={()=>{onDeleteCompetency(cellProps.dataItem)}}
                            onPublishClick={()=>{onPublishCompetency(cellProps.dataItem)}}
                            onCloseClick={()=>{onCloseCompetency(cellProps.dataItem)}}
                        />
                        )}
                    />
            

                    </CRUDPage>
                   
                    

                    {<Switch>
                        <Route path={ROUTES.COMPETENCY_MODEL.ADD} render={(props) => <CompetencyModelCreate {...props} onChange={()=>{setRefresh(refresh + 1)}} />}/>
                        <Route path={ROUTES.COMPETENCY_MODEL.EDIT} render={(props) => <CompetencyModelDetail {...props} onChange={()=>{setRefresh(refresh + 1)}} />}/>
                    </Switch>}

                </StackLayout>
            </section>
        </>
    );
};

export default CompetencyModelPage;