import React from "react";
import CustomDrawer from "components/layout/CustomDrawer";
import { useHistory } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { ROUTES } from 'routes';
import { addCompetencyModel } from "api/competency-models";

import useNotifications from 'hooks/use-notifications';
import {
    createSuccessNotification,
    createErrorNotification,
  } from 'utils/notifications-creator';


import CustomField from 'components/shared/forms/CustomField';
import CustomInput from 'components/shared/forms/CustomInput';
import {
    validateMaxLength,
    validateRequiredString,
  } from 'utils/validations';
  
  const validateRequired = (value) => {
    return (
      validateRequiredString(value) || validateMaxLength(value, 200)
    );
  };


const CompetencyModelCreate = (props) => {
    const history = useHistory();
    const [drawerIsOpen, setDrawerIsOpen] = React.useState(true);
    const notifications = useNotifications();
    const [drawerIsLoading, setDrawerIsLoading] = React.useState(false);

    const onCloseDrawer = ()=>{
        setDrawerIsOpen(false)
        history.push(ROUTES.COMPETENCY_MODEL.BASE);
    }


        const handleSubmit = async (dataItem) => {
            const data = {
              name:dataItem.name
          }

            const p = props
            try {
            await addCompetencyModel(data)

            notifications.push(
                createSuccessNotification('Item updated successfully.')
            );
            p.onChange()
            history.push(ROUTES.COMPETENCY_MODEL.BASE);

            } catch (err) {
            notifications.push(
                createErrorNotification('Something was wrong updating the item.')
            );
            } finally {
            setDrawerIsLoading(false)
            }



        };

    return (<>
        <CustomDrawer visible={drawerIsOpen} loading={drawerIsLoading} onClose={onCloseDrawer} title={'NEW COMPETENCY MODEL'} size="">
           
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps) => (
              <FormElement>
                <div>
                    <div className="mb-3">
                      <CustomField name={"name"} label="Competency model name *" component={CustomInput} maxLength={200} validator={validateRequired} />
                    </div>
                </div>
                <div className="v-spacer"></div>
                <div className="bottom-toolbar">
                  <div className="k-form-buttons">
                    <button
                      type={"submit"}
                      className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-success k-rounded-md"
                      disabled={!formRenderProps.allowSubmit}
                    >
                      CREATE
                    </button>
                  </div>
                </div>
              </FormElement>
            )}/>

        </CustomDrawer>
        </>
      
    );
};

export default CompetencyModelCreate;