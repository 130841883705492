import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { getClients } from "api/clients";
import {
  Card,
  CardBody,
  CardHeader,
  StackLayout,
} from '@progress/kendo-react-layout';
import {
  closeProject,
  deleteProject,
  getProjects,
  publishProject,
} from 'api/projects';
import PageTitle from 'components/layout/PageTitle';
import RightDrawer from 'components/layout/RightDrawer';
import EditProjectForm from 'components/projects/EditProjectForm';
import GridActionBar from 'components/projects/GridActionBar';
import NewProjectForm from 'components/projects/NewProjectForm';
import ProjectGrid from 'components/projects/grid/ProjectGrid';
import LoadingPanel from 'components/shared/LoadingPanel';
import useNotifications from 'hooks/use-notifications';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import { ROUTES } from 'routes';
import { DEFAULT_PAGE_SIZE } from 'settings/grid-settings';
import {
  createErrorNotification,
  createSuccessNotification,
} from 'utils/notifications-creator';
import {
  createAddProject,
  createCloseProject,
  createDeleteProject,
  createPublishProject,
  createSetProjects,
  createUpdateProject,
} from 'action-creators/projects-grid';
import projectsReducer from 'reducers/projects-grid-reducer';
import { ComboBox } from "@progress/kendo-react-dropdowns";

const paginate = (items, skip, take) => {
  return items.slice(skip, take + skip);
};

const initialPageState = {
  skip: 0,
  take: DEFAULT_PAGE_SIZE,
};

const ProjectPage = (props) => {
  const notifications = useNotifications();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [projects, dispatchProjects] = useReducer(projectsReducer, []);
  const [page, setPage] = useState(initialPageState);
  const [clients, setClients] = useState([]);
  const [projectStatuses, setProjectStatuses] = useState([{statusDescr:'Draft',status:0}, {statusDescr:'Open',status:1}, {statusDescr:'Close',status:2}]);
  
 const onViewAssessments = (e) =>{
  history.push(ROUTES.ASSESSMENT.BASE.replace(':projectId',e.projectId));
 }

 const onViewInvitees = (e) =>{
  history.push(ROUTES.INVITEES.BASE.replace(':projectId',e.projectId));
 }


  const createClickHandler = () => {
    console.log('create button clicked');
    history.push(ROUTES.PROJECTS.ADD);
  };

  const refreshClickHandler = () => {
    console.log('refresh button clicked');
    fetchProjects();
  };

  const editClickHandler = (projectId) => {
    console.log('edit button clicked -> ' + projectId);
    const editProjectNavigationUrl = ROUTES.PROJECTS.EDIT.replace(
      ':projectId',
      projectId
    );
    console.log(editProjectNavigationUrl);
    history.push(editProjectNavigationUrl);
  };

  const onDeleteProject = async(project) => {
    deleteClickHandler(project)
  }

  const deleteClickHandler = async (project) => {
    const { projectId, projectName } = project;
    const deletionConfirmed = window.confirm(
      'Are you sure you want to delete the project "' + projectName + '"?'
    );
    if (deletionConfirmed) {
      setLoading(true);
      try {
        await deleteProject(projectId);
        dispatchProjects(createDeleteProject(projectId));
        notifications.push(
          createSuccessNotification(
            `The project "${projectName}" has been deleted.`
          )
        );
        history.push(ROUTES.PROJECTS.LIST);
      } catch (errorResponse) {
        notifications.push(
          createErrorNotification(
            `We're sorry! We couldn't delete the project "${projectName}". Server response: ${errorResponse.status}`
          )
        );
      }
      setLoading(false);
    }
  };

  const pageChangeHandler = (e) => {
    console.debug('page size changed: ' + JSON.stringify(e.page));
    setPage(e.page);
  };

  const creatingHandler = (e) => {
    console.log('creating project...');
    setLoading(true);
  };

  const createdHandler = (createdProject) => {
    console.log('project created...');
    notifications.push(
      createSuccessNotification('The project has been created.')
    );

    dispatchProjects(createAddProject(createdProject));
    history.push(ROUTES.PROJECTS.EDIT.replace(':projectId',createdProject.projectId));
   
    setLoading(false);
  };

  const creationFailedHandler = (errorResponse) => {
    console.error('project creation failed');
    notifications.push(
      createErrorNotification(
        'Something went wrong creating the project. Server response: ' +
          errorResponse.status
      )
    );
    setLoading(false);
  };

  const formCancelClickHandler = () => {
    console.log('cancel clicked');
    history.push(ROUTES.PROJECTS.LIST);
  };

  const fetchProjects = useCallback(async () => {
    console.log('fetchProjects running...');
    setLoading(true);
    try {
      const projects = await getProjects();
      const clients = await getClients();
      setClients(clients)
      dispatchProjects(createSetProjects(projects));
    } catch (errorResponse) {
      notifications.push(
        createErrorNotification(
          `We're sorry! We couldn't load the projects. Server response: ${errorResponse.status}.`
        )
      );
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    console.log('running useEffect to fetching projects...');
    fetchProjects();
  }, [fetchProjects]);

  const drawerVisible =
    location.pathname.includes('new') || location.pathname.includes('edit');

  const updatingHandler = (submittedProject) => {
    setLoading(true);
  };

  const updatedHandler = (updatedProject) => {
    const { projectId, ...projectUpdateData } = updatedProject;
    dispatchProjects(createUpdateProject(projectId, projectUpdateData));
    notifications.push(
      createSuccessNotification('The project has been updated.')
    );
    history.push(ROUTES.PROJECTS.LIST);
    setLoading(false);
  };
  const updateFailedHandler = (errorResponse) => {
    notifications.push(
      createErrorNotification(
        "We're sorry! Something was wrong updating the project. Server response: " +
          errorResponse.status
      )
    );
    setLoading(false);
  };

  const onPublishProject = async(project) => {
    publishClickHandler(project)
  }

  const onCloseProject = async(project) => {
    closeClickHandler(project)
  }

  
  const publishClickHandler = async (project) => {
    const { projectId, projectName } = project;
    console.log('Publish clicked on project "' + projectName + '"');
    const publishConfirmed = window.confirm(
      `Are you sure you want to publish the project "${projectName}"? If you continue, all the persons you have chosen to join the project will receive an invitation email`
    );
    if (publishConfirmed) {
      setLoading(true);
      try {
        await publishProject(projectId);
        dispatchProjects(createPublishProject(projectId));
        notifications.push(
          createSuccessNotification(
            `The project "${projectName}" has been published successfully!`
          )
        );
        history.push(ROUTES.PROJECTS.LIST);
      } catch (errorResponse) {
        notifications.push(
          createErrorNotification(
            `We're sorry! We couldn't publish the project "${projectName}". Server response: ${errorResponse.status}`
          )
        );
      }
      setLoading(false);
    }
  };

  const closeClickHandler = async (project) => {
    const { projectId, projectName } = project;
    console.log('Close clicked on project "' + projectName + '"');
    const closeConfirmed = window.confirm(
      `Are you sure you want to close the project "${projectName}"? If you continue, all the persons you have chosen to join the project will not be able to work on it.`
    );
    if (closeConfirmed) {
      setLoading(true);
      try {
        await closeProject(projectId);
        dispatchProjects(createCloseProject(projectId));
        notifications.push(
          createSuccessNotification(
            `The project "${project.projectName}" has been closed successfully!`
          )
        );
      } catch (errorResponse) {
        notifications.push(
          createErrorNotification(
            `We're sorry! We couldn't close the project "${projectName}". Server response: ${errorResponse.status}`
          )
        );
      }
      setLoading(false);
    }
  };

  const [state, setState] = useState({
    globalFilter: "",
    clientFilter: null,
})


  const handleChange = (evt) => {
    const value = evt.value;
    setState({
        ...state,
        [evt.target.props.name]: value
    });
    setPage(Object.assign({}, initialPageState, { page: 1 }));
}

const handleChangeClientFilter = (event) => {
  setState({
      ...state,
      clientFilter: event.target.value,
  });
  setPage(Object.assign({}, initialPageState, { page: 1 }));
};

const handleChangeStatusFilter = (event) => {
  setState({
      ...state,
      statusFilter: event.target.value,
  });
  setPage(Object.assign({}, initialPageState, { page: 1 }));
};

const customFilterData = (e) => {
  console.log('customFilterData', state.globalFilter, e)
  const out = e?.filter(o => {
      
      return (state.clientFilter == null || o.clientId == state.clientFilter?.clientId)
        &&
        (state.statusFilter == null || o.status == state.statusFilter?.status)
        &&
      (state.globalFilter == '' || (state.globalFilter != '' && (o.projectName.toLowerCase().indexOf(state.globalFilter.toLowerCase()) != -1)))

  })

  console.log('out',out)
  return out
}

const filteredProjects = customFilterData(projects)
const visibleProjects = paginate(filteredProjects, page.skip, page.take);

  const filterField = <div>

  <Form render={({ allowSubmit, onSubmit }) => (
      <form className="k-form table-filter-form " style={{ display: 'flex', gap: '15px', width: '650px' }} onSubmit={(e) => e.preventDefault()}>

          <Field name="globalFilter" label="Search" component={Input} onChange={handleChange} />

          <ComboBox
                    data={clients}
                    textField="clientName"
                    dataItemKey="clientId"
                    value={state.clientFilter}
                    onChange={handleChangeClientFilter}
                    label="Client"
                    name="Client"
                />

          <ComboBox
                    data={projectStatuses}
                    textField="statusDescr"
                    dataItemKey="status"
                    value={state.statustFilter}
                    onChange={handleChangeStatusFilter}
                    label="Status"
                    name="Status"
                />

      </form>
  )}
  />

</div>

  return (
    <section>
      <StackLayout orientation="vertical" gap="1em">
        <PageTitle text="Atrain Projects" />
        
            <ProjectGrid
              projects={visibleProjects}
              total={projects.length}
              page={page}
              onAddButtonClick={createClickHandler}
              onPageChange={pageChangeHandler}
              onEditClick={editClickHandler}
              onDeleteClick={deleteClickHandler}
              onPublishClick={publishClickHandler}
              onCloseClick={closeClickHandler}
              onRefreshButtonClick={refreshClickHandler}
              filterField={filterField}
            />
         
      </StackLayout>

      <RightDrawer expanded={drawerVisible}>
        <Switch>
          <Route path={ROUTES.PROJECTS.ADD}>
            <NewProjectForm
              onCreating={creatingHandler}
              onCreated={createdHandler}
              onCreationFailed={creationFailedHandler}
              onCreationCancel={formCancelClickHandler}
            />
          </Route>
          <Route path={ROUTES.PROJECTS.EDIT}>
            <EditProjectForm
              onUpdating={updatingHandler}
              onUpdated={updatedHandler}
              onUpdateFailed={updateFailedHandler}
              onUpdateCancel={formCancelClickHandler}
              onPublishProject={onPublishProject}
              onCloseProject={onCloseProject}
              onDeleteProject={onDeleteProject}
              onViewAssessments={onViewAssessments}
              onViewInvitees={onViewInvitees}
            />
          </Route>
        </Switch>
      </RightDrawer>

      {loading && <LoadingPanel />}
    </section>
  );
};

export default ProjectPage;



/*
idProg > leenco self assessment
idSelf > elenco feedback
reset > ?? 
*/