import { getClient, updateClient } from 'api/clients';
import { getClients } from "api/clients";
import LoadingPanel from 'components/shared/LoadingPanel';
import useNotifications from 'hooks/use-notifications';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { LIST_CLIENT_ROUTE } from 'routes';
import {
  createSuccessNotification,
  createErrorNotification,
} from 'utils/notifications-creator';
import ClientForm from './ClientForm';

const EditClientForm = (props) => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState();

  const params = useParams();
  const clientId = parseInt(params.clientId);

  const notifications = useNotifications();
  const history = useHistory();

  const submitClickHandler = async (submittedFormData) => {
    const clientData = {
      clientName: submittedFormData.clientName.trim(),
      officeId:submittedFormData.officeId,
      officeName:submittedFormData.officeName,
    };
    try {
      await updateClient(clientId, clientData);
      notifications.push(
        createSuccessNotification('Client updated successfully.')
      );
      props.onClientUpdated?.(clientId, submittedFormData);
      history.replace(LIST_CLIENT_ROUTE);
    } catch (err) {
      notifications.push(
        createErrorNotification('Something was wrong updating the client.')
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchClientFormData = useCallback(
    async (clientId) => {
      setLoading(true);
      try {
        const client = await getClient(clientId);
        //const clients = await getClients();
        setFormData({
          clientName: client.clientName.trim(),
          offices:props.offices,
          officeId:client.officeId,
          clientId
        });
      } catch (err) {
        notifications.push({ type: 'error', text: err.message });
        history.push(LIST_CLIENT_ROUTE);
      } finally {
        setLoading(false);
      }
    },
    [history, notifications, props.offices]
  );

  useEffect(() => {
    fetchClientFormData(clientId);
  }, [fetchClientFormData, clientId]);

  return loading ? (
    <LoadingPanel />
  ) : (
    <ClientForm
      data={formData}
      onSubmitClick={submitClickHandler}
      onCancelClick={props.onClientUpdateCancel}
    />
  );
};

export default EditClientForm;
